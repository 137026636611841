import axios from "axios";
var url = process.env.REACT_APP_WEATHER_API_URL;

export const signUpWithEmailAndPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}/v1/sso/signup`, data)
      .then((response) => {
        if (response.data.success == true) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((err) => {
        reject(err.message);
        // alert('Please enter valid Email or Password')
      });
  });
};
