import React, { useEffect,useCallback} from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import * as Actions from '../store/actions/index';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


export default function Testing(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    // const name = window.$name;
    // console.log(name); //
    // const searchQuery =  (new URLSearchParams(window.location.search)).get("&");

    

    const testingResponse = useSelector((state) => state.testingReducer);
    // var value = 'b36bc78d-09a4-4182-b90f-2f40a087606b'
    var value = process.env.REACT_APP_WEATHER_API_KEY
    axios.defaults.headers.common['AppID'] = value;
    axios.defaults.withCredentials = true;
    const access_Token = "98894bec-ca63-4e50-a245-20a34bcc8142"
    // axios.defaults.headers.common['Authorization'] ='Bearer '+ access_Token;
    // const cookie = response.headers['set-cookie']
    // console.log('cookie',cookie)


    // const tokenApiResponse = useSelector((state) => state.verifyToken);

    console.log('hello1',testingResponse)

    useEffect(() => {
      getApiResponse();
    }, []);

    const getApiResponse = useCallback(() => {
      dispatch(
        Actions.testingAction()
      );
    });

    // {Object.entries(artistAdvertisement.data).length !== 0 &&
    //     artistAdvertisement.data.length !== 0 && (
    //       <a href={artistAdvertisement.data[0].link_url} target="_blank">
    //         <img src={artistAdvertisement.data[0].banner_url} />
    //       </a>
    //     )}

  return (
    <React.Fragment>
      <div className={classes.paper}>
      {Object.entries(testingResponse.data).length !== 0 &&
        testingResponse.data.length !== 0 && (
         <div>
             {testingResponse.data}
         </div>
        )}
      </div>
  </React.Fragment>

  );
}
