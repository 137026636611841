import React, { useEffect, useCallback } from "react";
import axios from "axios";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "../store/actions/index";
// import withReducer from "app/store/withReducer";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
// import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
// import Flutin from "./Images/Flutin_mini.png";
import facebook from "./Images/facebook.svg";
import EmailIcon from "./Images/EmailIcon.svg";
import WhiteEmailIcon from "./Images/WhiteEmailIcon.svg";
import lock from "./Images/lock.svg";
import Line from "./Images/Line.svg";
import Flutin from "./Images/Flutin.svg";
import FacebookLogin from "react-facebook-login";
import { Redirect } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";

import MainImage from "./Images/right-img.png";
import MainImageMobile from "./Images/Mobile-img.png";
const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper1: {
    // marginTop: theme.spacing(30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    // marginTop: theme.spacing(8),
    "@media (max-width: 767px)": {
      // marginTop: theme.spacing(4),
    },
  },
  lock: {
    marginBottom: "-1px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  container_color: {
    backgroundColor: "white",
    // paddingLeft: "32px",
    // paddingRight: "32px",
    "@media (max-width: 767px)": {
      maxWidth: "400px",
    },
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    background: "#6f39ce",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    color: "white",
    height: "60px",
    fontWeight: 800,
  },
  forget: {
    cursor: "pointer",
    fontSize: "14px",
    color: "#6f39ce",
    alignItems: "right",
    fontWeight: 500,
  },
  display: {
    display: "block",
  },

  align_signup: {
    textAlign: "center",
    cursor: "pointer",
    fontSize: "14px",
    display: "block",
    color: "#9A9A9A",
    marginBottom: "24px",
  },
  color: {
    color: "#6f39ce",
  },
  Sign_in: {
    marginTop: theme.spacing(3),
    color: "#9D9D9D",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
  },

  title: {
    color: "#767676",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
  },

  facebook_icon: {
    marginBottom: "-8px",
    marginLeft: "18px",
    marginTop: "-2px",
    float: "left",
  },

  email_icon: {
    marginBottom: "-5px",
    marginTop: "1px",
    marginLeft: "18px",
  },

  facebook_btn: {
    padding: "6px 16px",
    fontSize: "0.875rem",
    fontWeight: 900,
    lineHeight: "1.75",
    borderRadius: "4px",
    width: "100%",
    color: "#1665D8",
    background: "#D0E0F7",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    height: "56px",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
    fontFamily: "Roboto",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px",
    },
  },
  sub_container: {
    width: "100%",
    textAlign: "center",
  },
  or_text: {
    marginTop: "15px",
    marginBottom: "15px",
    color: "#969494",
    fontWeight: 900,
    fontSize: "16px",
  },
  email_img: {
    display: "inline-block",
    float: "left",
  },
  login_email: {
    padding: "6px 16px",
    fontSize: "0.875rem",
    fontWeight: 900,
    lineHeight: "1.75",
    borderRadius: "4px",
    width: "100%",
    color: "#6f39ce",
    background: "white",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    height: "56px",
    border: "1px solid #6f39ce",
    boxSizing: "border-box",
    fontFamily: "Roboto",
    fontSize: "18px",
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px",
    },
  },
  register_email: {
    marginBottom: "30px",
    cursor: "pointer",
    padding: "6px 16px",
    fontSize: "0.875rem",
    fontWeight: 900,
    lineHeight: "1.75",
    borderRadius: "4px",
    width: "100%",
    color: "white",
    background: "#6f39ce",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    height: "56px",
    boxSizing: "border-box",
    fontFamily: "Roboto",
    fontSize: "18px",
    border: "none",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px",
    },
  },
  login_email_container: {
    marginBottom: "18px",
    textAlign: "center",
  },
  terms_container: {
    padding: "0px",
    color: "#767676",
    fontWeight: 500,
    fontSize: "14px",
  },
  privacy: {
    textAlign: "right",
  },
  cursor: {
    cursor: "pointer",
  },
  line: {
    marginBottom: "5px",
  },
  security: {
    padding: "8px",
  },
  align_Back: {
    textAlign: "center",
    cursor: "pointer",
    fontSize: "14px",
    display: "block",
    color: "#9A9A9A",
    marginBottom: "24px",
    display: "flex",
    justifyContent: "center",
  },
  sub_text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    color: "#767676",
  },
  gradient_background: {
    "@media (max-width: 767px)": {
      backgroundImage: "url(" + MainImageMobile + ")",
      backgroundSize: "cover",
      position: "absolute",
      width: "100%",
      top: "-14px",
      height: "320px",
    },
  },
  mainHeader: {
    marginTop: "77px",
    "@media (max-width: 767px)": {
      marginTop: "196px",
    },
  },
  mainHeader1: {
    fontFamily: "Exo",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "60px",
    textAlign: "center",
    color: "#FFFFFF",
    "@media (max-width: 767px)": {
      fontSize: "18px",
      lineHeight: "28px",
    },
  },
  mainHeader2: {
    fontFamily: "Exo",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#FFFFFF",
    "@media (max-width: 767px)": {
      fontSize: "18px",
      lineHeight: "28px",
    },
  },
  mainImage: {
    backgroundImage: "url(" + MainImage + ")",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  mainGrid: {
    backgroundColor: "white",
    "@media (min-width: 767px)": {
      height: "100vh",
    },
  },
  grid: {
    "@media (max-width: 767px)": {
      zIndex: 1,
      position: "relative",
      top: "235px",
      padding: "16px",
    },
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loginResponse = useSelector((state) => state.login);
  const fbLoginResponse = useSelector((state) => state.fbLogin);
  const emailFromURL = new URLSearchParams(window.location.search).get("email");
  const [email, setemail] = React.useState(emailFromURL ? emailFromURL : "");
  const [disableEmail] = React.useState(emailFromURL ? true : false);
  const [Message, setMessage] = React.useState("");
  const [fbEmail, setfbEmail] = React.useState("");
  const [checkState, setcheckState] = React.useState(false);
  const [Title, setTitle] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [withoutEmail, setwithoutEmail] = React.useState(true);
  const [Verify, setVerify] = React.useState("false");

  const [checkLogin, setcheckLogin] = React.useState(false);
  // const [FbEmail, setFbEmail] = React.useState("");
  var AccessToken;
  var getFacebookResponse = [];
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: true, // enable logs
  };
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID, { debug: true });
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, options);

  const [OpenState, setOpen] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState1, setOpen1] = React.useState({
    open1: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState2, setOpen2] = React.useState({
    open2: false,
    vertical: "top",
    horizontal: "center",
  });

  const [OpenState3, setOpen3] = React.useState({
    open3: false,
    vertical: "top",
    horizontal: "center",
  });

  const [OpenState4, setOpen4] = React.useState({
    open4: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = OpenState;
  const { open1 } = OpenState1;
  const { open2 } = OpenState2;
  const { open3 } = OpenState3;
  const { open4 } = OpenState4;

  var searchQuery = new URLSearchParams(window.location.search).get("redirect");
  if (searchQuery !== null && searchQuery !== "null") {
    searchQuery = searchQuery.replace("?", "&");
  }

  axios.defaults.withCredentials = true;
  delete axios.defaults.headers.common["Authorization"];

  var value = process.env.REACT_APP_WEATHER_API_KEY;
  var FB_AppId = process.env.REACT_APP_FACEBOOK_APP_ID;

  axios.defaults.headers.common["AppID"] = value;
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const onChangeEmail = (event) => {
    setemail(event.target.value);
  };

  const onChangeFbEmail = (event) => {
    setfbEmail(event.target.value);
    if (
      event.target.value.includes("@") &&
      event.target.value.includes(".com")
    ) {
      setcheckState(true);
    } else {
      setcheckState(false);
    }
  };

  const onChangePassword = (event) => {
    setpassword(event.target.value);
  };

  const onClickLogin = useCallback((data) => {
    dispatch(Actions.loginAction(data));
  });

  const onClickFbLogin = useCallback((data) => {
    dispatch(Actions.fbLoginAction(data));
  });

  const handleClick = (newState) => {
    setOpen({ open: true, ...newState });
  };

  const handleClick1 = (newState1) => {
    setOpen1({ open1: true, ...newState1 });
  };

  const handleClick2 = (newState2) => {
    setOpen2({ open2: true, ...newState2 });
  };

  const handleClick3 = (newState3) => {
    setOpen3({ open3: true, ...newState3 });
  };

  const handleClick4 = (newState4) => {
    setOpen4({ open4: true, ...newState4 });
  };

  const handleClose = () => {
    setOpen({ ...OpenState, open: false });
    setOpen1({ ...OpenState1, open1: false });
    setOpen2({ ...OpenState2, open2: false });
    setOpen3({ ...OpenState3, open3: false });
    setOpen4({ ...OpenState4, open4: false });
  };

  const responseFacebook = (response) => {
    if (response.status !== "unknown") {
      let data = {
        token: response.accessToken,
        userDetails: response,
      };

      onClickFbLogin(data);
      // setemail("");
      // getFacebookResponse.push(response);
      // console.log("login", getFacebookResponse);

      // AccessToken = response.accessToken;
    }

    // if (!response.email) {
    //   setwithoutEmail(false);
    // }
  };

  const onFacebook = () => {
    ReactGA.event({
      category: "sso",
      action: "continueWithFacebook",
      label: "continueWithFacebook",
    });
  };

  const clickProceed = () => {
    // console.log(email);
    // getFacebookResponse.push({ email: email });
    // console.log("proceed", getFacebookResponse);
  };

  const getLoginUi = () => {
    setcheckLogin(true);
    ReactGA.event({
      category: "sso",
      action: "loginWithEmail",
      label: "loginWithEmail",
    });
  };

  const getMainUi = () => {
    setcheckLogin(false);
    ReactGA.event({
      category: "sso",
      action: "Back",
      label: "loginWithEmail",
    });
  };

  const Terms = () => {
    ReactGA.event({
      category: "sso",
      action: "Terms",
      label: "Terms",
    });
  };

  const Privacy = () => {
    ReactGA.event({
      category: "sso",
      action: "Privacy",
      label: "Privacy",
    });
  };

  useEffect(() => {
    onClickLogin();
    if (!searchQuery || searchQuery == "") {
      searchQuery = process.env.REACT_APP_CREATOR_HOST;
    }
    if (searchQuery !== "null" && searchQuery !== null) {
      var parser = new URL(`${searchQuery}`);
      if (
        parser.host == "dev.creator.flutin.com" ||
        parser.host == "creator.flutin.com" ||
        parser.host == "localhost:4200"
      ) {
        // setTitle(process.env.REACT_APP_CREATOR);
      } else if (
        parser.host == "dev.live.flutin.com" ||
        parser.host == "live.flutin.com" ||
        parser.host == "localhost:4201"
      ) {
        setTitle(process.env.REACT_APP_LIVE);
      } else {
        setTitle("");
      }
    }
  }, []);

  useEffect(() => {
    var responseToWindow = {};
    if (!searchQuery || searchQuery == "") {
      searchQuery = process.env.REACT_APP_CREATOR_HOST;
    }
    if (loginResponse.success != false) {
      if (
        loginResponse.data.success == true &&
        loginResponse.data.verified == true
      ) {
        handleClick({ vertical: "top", horizontal: "center" });
        if (searchQuery !== "null" && searchQuery !== null) {
          var parser = new URL(`${searchQuery}`);
          ReactGA.event({
            category: "sso",
            action: "SignIn successfully",
            label: "SignIn-Automatic",
          });
          window.location = `${parser.origin}/tokenAuth/verify?redirect=${searchQuery}&token=${loginResponse.data.data.token}`;
        }
      } else if (
        loginResponse.data.success == true &&
        loginResponse.data.verified == false
      ) {
        if (searchQuery !== "null" && searchQuery !== null) {
          var parser = new URL(`${searchQuery}`);
          if (
            parser.host == "dev.live.flutin.com" ||
            parser.host == "live.flutin.com" ||
            parser.host == "localhost:4201"
          ) {
            ReactGA.event({
              category: "sso",
              action: "SignIn successfully",
              label: "SignIn-Live-Email",
            });
            handleClick({ vertical: "top", horizontal: "center" });

            var parser = new URL(`${searchQuery}`);

            window.location = `${parser.origin}/tokenAuth/verify?redirect=${searchQuery}&token=${loginResponse.data.data.token}`;
          } else {
            if (loginResponse.data.success == true) {
              ReactGA.event({
                category: "sso",
                action: "SignIn successfully",
                label: "SignIn-Creator-Email",
              });
              setMessage(loginResponse.data.message);
              handleClick4({ vertical: "top", horizontal: "center" });
            }
            getLinkAgain();
          }
        } else {
          ReactGA.event({
            category: "sso",
            action: "SignIn successfully",
            label: "SignIn-Accounts-Email",
          });
          checkEmailVerify();
        }
      }
      if (
        searchQuery !== "null" &&
        searchQuery !== null ) {
        var parser = new URL(`${searchQuery}`);
        responseToWindow.loginSuccessURL = `${parser.origin}/tokenAuth/verify?redirect=${searchQuery}&token=${loginResponse.data.data.token}`;
        responseToWindow.message = "Login success";
        if (Object.keys(window.opener).length != 0) {
          window.opener.postMessage(
            JSON.stringify(responseToWindow),
            parser.origin
          );
          window.close();
        }
      }
    }
    if (
      loginResponse.success == false &&
      loginResponse.error.success == true &&
      loginResponse.error.data != undefined
    ) {
      handleClick1({ vertical: "top", horizontal: "center" });
    }
  }, [loginResponse]);

  const checkEmailVerify = () => {
    if (
      loginResponse.data.success == true &&
      loginResponse.data.verified == true
    ) {
      handleClick({ vertical: "top", horizontal: "center" });
    } else if (
      loginResponse.data.success == true &&
      loginResponse.data.verified == false
    ) {
      if (loginResponse.data.success == true) {
        setMessage(loginResponse.data.message);
        handleClick4({ vertical: "top", horizontal: "center" });
      }
      getLinkAgain();
    } else handleClick({ vertical: "top", horizontal: "center" });
  };

  const getLinkAgain = () => {
    if (searchQuery !== "null" && searchQuery !== null) {
      props.history.push("/getLink?redirect=" + searchQuery);
    } else {
      props.history.push("/getLink");
    }
    loginResponse.success = false;
  };

  useEffect(() => {
    if (fbLoginResponse.success != false) {
      if (fbLoginResponse.data.success == true) {
        var responseToWindow = {};
        handleClick({ vertical: "top", horizontal: "center" });
        ReactGA.set({ userId: fbLoginResponse.data.userId });
        if (searchQuery !== "null" && searchQuery !== null) {
          var parser = new URL(`${searchQuery}`);
          if (
            parser.host == "dev.live.flutin.com" ||
            parser.host == "live.flutin.com" ||
            parser.host == "localhost:4201"
          ) {
            if (fbLoginResponse.data.signUp == true) {
              ReactGA.event({
                category: "sso",
                action: "SignUp successfully",
                label: "SignUp-Live-Facebook",
              });
              ReactPixel.track("CompleteRegistration", {
                content_category: "Facebook-Live",
              });
            } else {
              ReactGA.event({
                category: "sso",
                action: "SignIn successfully",
                label: "SignIn-Live-Facebook",
              });
            }
          } else {
            if (fbLoginResponse.data.signUp == true) {
              ReactGA.event({
                category: "sso",
                action: "SignUp successfully",
                label: "SignUp-Creator-Facebook",
              });
              ReactPixel.track("CompleteRegistration", {
                content_category: "Facebook-Creator",
              });
            } else {
              ReactGA.event({
                category: "sso",
                action: "SignIn successfully",
                label: "SignIn-Creator-Facebook",
              });
            }
          }
          if (!searchQuery.includes("flutin.com")) {
            var parser = new URL(`${searchQuery}`);
            responseToWindow.loginSuccessURL = `${parser.origin}/tokenAuth/verify?redirect=${searchQuery}&token=${fbLoginResponse.data.data.token}`;
            responseToWindow.message = "Login success";
            window.opener.postMessage(
              JSON.stringify(responseToWindow),
              parser.origin
            );
            window.close();
          }

          window.location = `${parser.origin}/tokenAuth/verify?redirect=${searchQuery}&token=${fbLoginResponse.data.data.token}`;
        } else {
          if (fbLoginResponse.data.signUp == true) {
            ReactGA.event({
              category: "sso",
              action: "SignUp successfully",
              label: "SignUp-Accounts-Facebook",
            });
            ReactPixel.track("CompleteRegistration", {
              content_category: "Facebook",
            });
          } else {
            ReactGA.event({
              category: "sso",
              action: "SignIn successfully",
              label: "SignIn-Accounts-Facebook",
            });
          }
        }
      }
    }
    if (
      fbLoginResponse.success == false &&
      fbLoginResponse.error.success == true &&
      fbLoginResponse.error.data != undefined
    ) {
      handleClick3({ vertical: "top", horizontal: "center" });
    }
  }, [fbLoginResponse]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    var credentials = btoa(email + ":" + password);
    var value = process.env.REACT_APP_WEATHER_API_KEY;
    const data = {
      redirect:
        searchQuery !== "null" && searchQuery !== null
          ? `${searchQuery}`
          : null,
    };

    var basicAuth = credentials;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Authorization"] = "Basic " + basicAuth;
    axios.defaults.headers.common["AppID"] = value;

    if (email == "" || password == "") {
      handleClick2({ vertical: "top", horizontal: "center" });
    } else {
      ReactGA.event({
        category: "sso",
        action: "signIn",
        label: "signIn",
      });
      await onClickLogin(data);
    }
  };

  const handleForget = () => {
    ReactGA.event({
      category: "sso",
      action: "forgotPassword",
      label: "forgotPassword",
    });
    if (searchQuery != null) {
      props.history.push("/Email?redirect=" + searchQuery);
    } else {
      props.history.push("/Email");
    }
  };

  const handleSignup = () => {
    ReactGA.event({
      category: "sso",
      action: "registerWithEmail",
      label: "registerWithEmail",
    });
    if (searchQuery != null) {
      props.history.push("/Sign_up?redirect=" + searchQuery);
    } else {
      props.history.push("/Sign_up");
    }
  };

  const clickLogo = () => {
    if (searchQuery !== null) {
      props.history.push("/?redirect=" + searchQuery);
    } else {
      props.history.push("/");
    }
    ReactGA.event({
      category: "sso",
      action: "logo",
      label: "logo",
    });
  };

  // const onLogout = () => {
  //   console.log(AccessToken);
  //   window.FB.logout(AccessToken);
  // };

  // const getLikes = () => {
  //   window.FB.login(
  //     function (response) {
  //       console.log(response);
  //     },
  //     {
  //       scope: "email",
  //       auth_type: "rerequest",
  //     }
  //   );
  // };

  return (
    <div>
      <div className={classes.gradient_background}></div>
      <Grid
        container
        direction="row"
        justifycontent="center"
        alignItems="center"
        className={classes.mainGrid}
        style={{}}
      >
        <Grid item md={6} lg={7} xs={12} className={classes.grid}>
          <Container
            className={classes.container_color}
            component="main"
            maxWidth="sm"
          >
            <CssBaseline />
            {/* <div className={classes.gradient_background} > */}
            <Container
              className={classes.container_color}
              component="main"
              maxWidth="xs"
            >
              <div className={classes.paper}>
                <div className={classes.avatar}>
                  <img
                    style={{ cursor: "pointer", width: "130px" }}
                    onClick={clickLogo}
                    src={Flutin}
                  />
                </div>
                <div className={classes.sub_text}>Flutin for Creators</div>

                {!checkLogin ? (
                  <div className={classes.sub_container}>
                    <div style={{ marginTop: "30px" }}>
                      <FacebookLogin
                        appId={FB_AppId}
                        fields="name,picture,email"
                        scope="public_profile,email"
                        callback={responseFacebook}
                        cssClass={classes.facebook_btn}
                        disableMobileRedirect={true}
                        onClick={onFacebook}
                        icon={
                          <img
                            className={classes.facebook_icon}
                            src={facebook}
                          />
                        }
                        textButton="Continue with facebook"
                      />
                    </div>
                    <Typography
                      className={classes.or_text}
                      component="h1"
                      variant="h6"
                    >
                      <img className={classes.line} src={Line} /> &nbsp;OR
                      &nbsp;
                      <img className={classes.line} src={Line} />
                    </Typography>
                    <div className={classes.login_email_container}>
                      <button
                        onClick={getLoginUi}
                        className={classes.login_email}
                      >
                        <div className={classes.email_img}>
                          <img className={classes.email_icon} src={EmailIcon} />
                        </div>
                        Login with Email
                      </button>
                    </div>
                    <div className={classes.login_email_container}>
                      <button
                        onClick={handleSignup}
                        className={classes.register_email}
                      >
                        <div className={classes.email_img}>
                          <img
                            className={classes.email_icon}
                            src={WhiteEmailIcon}
                          />
                        </div>
                        Register with Email
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className={classes.sub_container}>
                    <Typography
                      className={classes.title}
                      component="h2"
                      variant="h5"
                    >
                      <b>{Title}</b>
                    </Typography>
                    <Typography
                      className={classes.Sign_in}
                      component="h1"
                      variant="h6"
                    >
                      Sign In with Email
                    </Typography>
                    <ValidatorForm className={classes.form}>
                      {withoutEmail ? (
                        <div>
                          <TextValidator
                            disabled={disableEmail}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            value={email}
                            type="email"
                            onChange={onChangeEmail}
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            validators={["required", "isEmail"]}
                            errorMessages={[
                              "this field is required",
                              "email is not valid",
                            ]}
                            autoFocus
                          />
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={password}
                            onChange={onChangePassword}
                            name="password"
                            inputProps={{ maxLength: 200 }}
                            label="Password"
                            type="password"
                            id="password"
                            validators={["required"]}
                            errorMessages={["this field is required"]}
                          />
                          <Grid container className={classes.display}>
                            <Grid item style={{ textAlign: "right" }}>
                              <Typography
                                onClick={handleForget}
                                className={classes.forget}
                              >
                                Forgot Your password?
                              </Typography>
                            </Grid>
                          </Grid>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSubmit}
                          >
                            Sign In
                          </Button>
                          {/* <button onClick={onLogout}>Logout</button>
           <button onClick={getLikes}>Likes</button> */}
                          <Grid container className={classes.display}>
                            <Grid item>
                              <Typography
                                onClick={handleSignup}
                                className={classes.align_signup}
                              >
                                Don't have an account?
                                <b className={classes.color}> Sign Up</b>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container className={classes.display}>
                            <Grid item>
                              <Typography
                                onClick={getMainUi}
                                className={classes.align_Back}
                              >
                                <div>
                                  <svg
                                    style={{ marginTop: "1px" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="18"
                                    fill="#6200EE"
                                    class="bi bi-chevron-left"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                    />
                                  </svg>
                                </div>
                                <b className={classes.color}>Back</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        <div>
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            value={fbEmail}
                            type="email"
                            onChange={onChangeFbEmail}
                            label="Email Address"
                            name="email"
                            autoComplete="off"
                            validators={["required", "isEmail"]}
                            errorMessages={[
                              "this field is required",
                              "email is not valid",
                            ]}
                            autoFocus
                          />
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={clickProceed}
                            disabled={!checkState}
                          >
                            Proceed
                          </Button>
                        </div>
                      )}
                    </ValidatorForm>
                  </div>
                )}
              </div>
              <Snackbar
                open={open}
                key="topcenter"
                autoHideDuration={4000}
                anchorOrigin={{ vertical, horizontal }}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  Login success!
                </Alert>
              </Snackbar>
              <Snackbar
                open={open1}
                key="topcenter1"
                autoHideDuration={4000}
                anchorOrigin={{ vertical, horizontal }}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {loginResponse.error.data}
                </Alert>
              </Snackbar>
              <Snackbar
                open={open2}
                key="topcenter2"
                autoHideDuration={4000}
                anchorOrigin={{ vertical, horizontal }}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="info">
                  Please fill both the fields
                </Alert>
              </Snackbar>
              <Snackbar
                open={open3}
                key="topcenter3"
                autoHideDuration={4000}
                anchorOrigin={{ vertical, horizontal }}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {fbLoginResponse.error.data}
                </Alert>
              </Snackbar>
              <Snackbar
                open={open4}
                key="topcenter4"
                autoHideDuration={4000}
                anchorOrigin={{ vertical, horizontal }}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="info">
                  {/* Your email is not verified. Please verify your email */}
                  {Message}
                </Alert>
              </Snackbar>
            </Container>

            {!checkLogin ? (
              <Container
                className={classes.terms_container}
                component="main"
                maxWidth="xs"
              >
                <Grid container className={classes.security}>
                  <Grid item xs={6}>
                    <div>
                      <img className={classes.lock} src={lock}></img> Secure
                    </div>
                  </Grid>
                  <Grid className={classes.privacy} item xs={6}>
                    <div className={classes.cursor}>
                      <a
                        onClick={Privacy}
                        target="_blank"
                        style={{ textDecoration: "none", color: "#767676" }}
                        href="https://creator.flutin.com/privacy"
                      >
                        Privacy
                      </a>{" "}
                      &nbsp; &nbsp;{" "}
                      <a
                        onClick={Terms}
                        target="_blank"
                        style={{ textDecoration: "none", color: "#767676" }}
                        href="https://flutin.com/terms/"
                      >
                        Terms
                      </a>
                    </div>
                    {/* <div>Privacy</div> */}
                  </Grid>
                </Grid>
              </Container>
            ) : (
              <div></div>
            )}
            {/* </div> */}
          </Container>
        </Grid>

        <Hidden smDown>
          <Grid item md={6} lg={5} className={classes.mainImage}></Grid>
        </Hidden>
      </Grid>
    </div>
  );
}
