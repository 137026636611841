import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "../store/actions/forgetPass.action";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Flutin from "./Images/Flutin.svg";

// import withReducer from "app/store/withReducer";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper1: {
    marginTop: theme.spacing(30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(8),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    background: "#122BA9",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    color: "white",
    height: "60px",
    fontWeight: 800,
  },
  text_class: {
    cursor: "pointer",
    fontSize: "14px",
    textAlign: "right",
    color: "#6200EE",
    fontWeight: 500,
  },
  container_color: {
    backgroundColor: "white",
    paddingLeft: "32px",
    paddingRight: "32px",
  },
  display: {
    display: "block",
  },
}));

export default function ForgetPassword(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const PwdResponse = useSelector((state) => state.ForgetPass);
  
  const [newPassword, setnewPassword] = React.useState("");
  const [confirmPassword, setconfirmPassword] = React.useState("");
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

  const [OpenState, setOpen] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState1, setOpen1] = React.useState({
    open1: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState2, setOpen2] = React.useState({
    open2: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState3, setOpen3] = React.useState({
    open3: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = OpenState;
  const { open1 } = OpenState1;
  const { open2 } = OpenState2;
  const { open3 } = OpenState3;

  const searchQuery = new URLSearchParams(window.location.search).get("token");
  const token = searchQuery;
  const searchQueryRedirect = new URLSearchParams(window.location.search).get(
    "redirect"
  );

  // localStorage.setItem('jwt_token', token);

  // console.log('token',token)

  const handleClick = (newState) => {
    setOpen({ open: true, ...newState });
  };
  const handleClick1 = (newState1) => {
    setOpen1({ open1: true, ...newState1 });
  };
  const handleClick2 = (newState2) => {
    setOpen2({ open2: true, ...newState2 });
  };
  const handleClick3 = (newState3) => {
    setOpen3({ open3: true, ...newState3 });
  };

  const handleClose = () => {
    setOpen({ ...OpenState, open: false });
    setOpen1({ ...OpenState1, open1: false });
    setOpen2({ ...OpenState2, open2: false });
    setOpen3({ ...OpenState3, open3: false });
  };

  const onChangeNewPassword = (event) => {
    setnewPassword(event.target.value);
  };

  const onChangeConfirmPassword = (event) => {
    setconfirmPassword(event.target.value);
  };

  const onClickForget = useCallback((data) => {
    dispatch(Actions.ForgetPassAction(data));
  });

  // const name = window.$name;
  // console.log(name); //
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  

  const handleForgetPass = async (event) => {
    try {
      event.preventDefault();
      if (
        newPassword === confirmPassword &&
        newPassword != "" &&
        newPassword.length >= 6
      ) {
        const data = {
          token: token,
          password: newPassword,
          confirmPassword: confirmPassword,
        };
        ReactGA.event({
          category: "sso",
          action: "resetPassword",
          label: "resetPassword",
        });
        await onClickForget(data);
        // window.location = searchQueryRedirect;
      } else if (
        newPassword == "" ||
        confirmPassword == "" ||
        newPassword.length < 6
      ) {
        handleClick1({ vertical: "top", horizontal: "center" });
      } else {
        handleClick2({ vertical: "top", horizontal: "center" });
      }
    } catch (error) {
      console.error(error);
    }
    
  };
  
  useEffect(() => {
    if (PwdResponse.success != false) {
      if (PwdResponse.data.success == true) {
        handleClick({ vertical: "top", horizontal: "center" });
        if (searchQueryRedirect !== null && searchQueryRedirect !== "null") {
          props.history.push("/");
        } else {
          window.location = searchQueryRedirect;
        }
      }
    }
    if (PwdResponse.success == false && PwdResponse.error != null) {
      handleClick3({ vertical: "top", horizontal: "center" });
    }
  }, [PwdResponse]);

  const handleSignIn = () => {
    if (searchQueryRedirect !== null && searchQueryRedirect !== "null") {
      props.history.push("/?redirect=" + searchQueryRedirect);
    } else {
      props.history.push("/");
    }
    ReactGA.event({
      category: "sso",
      action: "backToLogin",
      label: "backToLogin",
    });
  };

  const clickLogo = () => {
    if (searchQueryRedirect !== null && searchQueryRedirect !== "null") {
      props.history.push("/?redirect=" + searchQueryRedirect);
    } else {
      props.history.push("/");
    }
    ReactGA.event({
      category: "sso",
      action: "logo",
      label: "logo",
    });
  };

  return (
    <React.Fragment>
      {token == null ? (
        <div className={classes.paper1}>
          <CircularProgress disableShrink />
          Loading...
        </div>
      ) : (
        <Container
          className={classes.container_color}
          component="main"
          maxWidth="xs"
        >
          <CssBaseline />
          <div className={classes.paper}>
            <div className={classes.avatar}>
              <img
                style={{ cursor: "pointer" }}
                onClick={clickLogo}
                src={Flutin}
              />
            </div>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="password"
                id="password1"
                value={newPassword}
                inputProps={{ maxLength: 200 }}
                onChange={onChangeNewPassword}
                label="New Password"
                name="new password"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={confirmPassword}
                onChange={onChangeConfirmPassword}
                inputProps={{ maxLength: 200 }}
                name="Confirm password"
                label="Confirm Password"
                type="password"
                id="password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleForgetPass}
              >
                Submit
              </Button>
              <Grid container className={classes.display}>
                <Grid item>
                  <Typography
                    onClick={handleSignIn}
                    className={classes.text_class}
                  >
                    Back to Login
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </div>
          <Snackbar
            open={open}
            key="topcenter"
            autoHideDuration={4000}
            anchorOrigin={{ vertical, horizontal }}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              {PwdResponse.msg}
            </Alert>
          </Snackbar>
          <Snackbar
            open={open1}
            key="topcenter1"
            autoHideDuration={4000}
            anchorOrigin={{ vertical, horizontal }}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info">
              Password must be more than 6 characters
            </Alert>
          </Snackbar>
          <Snackbar
            open={open2}
            key="topcenter2"
            autoHideDuration={4000}
            anchorOrigin={{ vertical, horizontal }}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              Your password must be same
            </Alert>
          </Snackbar>
          <Snackbar
            open={open3}
            key="topcenter3"
            autoHideDuration={4000}
            anchorOrigin={{ vertical, horizontal }}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              {PwdResponse.error}
            </Alert>
          </Snackbar>
        </Container>
      )}
    </React.Fragment>
  );
}
