import * as Actions from "../actions/testing.action";

const initialState = {
    isLoading: false,
    data: [],
    success: false,
    error: {}
  };

const testingReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.TESTING_SUCCESS:
            return {
              ...state,
              success: true,
              isLoading: false,
            };
          
          case Actions.TESTING_ERROR:
            return {
              ...state,
              success: false,
              isLoading: false,
              error: action.payload,
            };
      
          default:
            return {
              ...state,
            };
    }
}
export default testingReducer;
