import axios from 'axios';
var url = process.env.REACT_APP_WEATHER_API_URL

// const uuid = window.localStorage.getItem('jwt_token');
// axios.defaults.headers.common['jwt_token'] = uuid;
export const ChangePassword = (data) => {
    
    return new Promise((resolve, reject) => {
        axios.post(`${url}/v1/sso/changePassword`,data)
        .then(response => {

            if ( response.data.success == true )
            {
                resolve(response.data);
            }
            else
            {
                reject(response.data.error);

            }
        })
        .catch( err => {
            reject(err.message)
        })
        ;
    });
};
