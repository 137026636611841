import React, { useEffect, useCallback } from "react";
import axios from "axios";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "../store/actions/index";
// import withReducer from "app/store/withReducer";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
// import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";

// import Flutin from "./Images/Flutin_mini.png";
import facebook from "./Images/facebook.svg";
import EmailIcon from "./Images/EmailIcon.svg";
import WhiteEmailIcon from "./Images/WhiteEmailIcon.svg";
import lock from "./Images/lock.svg";
import Line from "./Images/Line.svg";
import Flutin from "./Images/Flutin.svg";
import FlutinLogoWithCamera from "./Images/FlutinLogoWithCamera.svg";
import MainImage from "./Images/right-img.png";
import AppSumoLogo from "./Images/Appsumo.svg";
import TacoLogo from "./Images/Taco.svg";
import FacebookLogin from "react-facebook-login";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper1: {
    marginTop: theme.spacing(30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  lock: {
    marginBottom: "-1px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  container_color: {
    backgroundColor: "white",
    paddingLeft: "32px",
    paddingRight: "32px",
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    background: "#9923FA",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    color: "white",
    height: "60px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.02em",
    color: "#FFFFFF",
  },
  forget: {
    cursor: "pointer",
    fontSize: "14px",
    color: "#6200EE",
    alignItems: "right",
    fontWeight: 500,
  },
  display: {
    display: "block",
  },

  align_signup: {
    textAlign: "center",
    cursor: "pointer",
    fontSize: "14px",
    display: "block",
    color: "#9A9A9A",
    marginBottom: "24px",
  },
  color: {
    color: "#6200EE",
  },
  Sign_in: {
    marginTop: theme.spacing(3),
    color: "#9D9D9D",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
  },

  title: {
    color: "#767676",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
  },

  facebook_icon: {
    marginBottom: "-8px",
    marginLeft: "18px",
    marginTop: "-2px",
    float: "left",
  },

  email_icon: {
    marginBottom: "-5px",
    marginTop: "1px",
    marginLeft: "18px",
  },

  facebook_btn: {
    padding: "6px 16px",
    fontSize: "0.875rem",
    fontWeight: 900,
    lineHeight: "1.75",
    borderRadius: "4px",
    width: "100%",
    color: "#1665D8",
    background: "#D0E0F7",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    height: "56px",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
    fontFamily: "Roboto",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px",
    },
  },
  sub_container: {
    width: "100%",
    textAlign: "center",
  },
  or_text: {
    marginTop: "15px",
    marginBottom: "15px",
    color: "#969494",
    fontWeight: 900,
    fontSize: "16px",
  },
  email_img: {
    display: "inline-block",
    float: "left",
  },
  login_email: {
    padding: "6px 16px",
    fontSize: "0.875rem",
    fontWeight: 900,
    lineHeight: "1.75",
    borderRadius: "4px",
    width: "100%",
    color: "#6B77FF",
    background: "white",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    height: "56px",
    border: "1px solid #6B77FF",
    boxSizing: "border-box",
    fontFamily: "Roboto",
    fontSize: "18px",
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px",
    },
  },
  register_email: {
    marginBottom: "30px",
    cursor: "pointer",
    padding: "6px 16px",
    fontSize: "0.875rem",
    fontWeight: 900,
    lineHeight: "1.75",
    borderRadius: "4px",
    width: "100%",
    color: "white",
    background: "#122BA9",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    height: "56px",
    boxSizing: "border-box",
    fontFamily: "Roboto",
    fontSize: "18px",
    border: "none",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px",
    },
  },
  login_email_container: {
    marginBottom: "18px",
    textAlign: "center",
  },
  terms_container: {
    background: "#f8f8f8",
    padding: "0px",
    color: "#767676",
    fontWeight: 500,
    fontSize: "14px",
  },
  privacy: {
    textAlign: "right",
  },
  cursor: {
    cursor: "pointer",
  },
  line: {
    marginBottom: "5px",
  },
  security: {
    padding: "8px",
  },
  align_Back: {
    textAlign: "center",
    cursor: "pointer",
    fontSize: "14px",
    display: "block",
    color: "#9A9A9A",
    marginBottom: "24px",
    display: "flex",
    justifyContent: "center",
  },
  taco_background: {
    width: "100%",
    // height: "48px",
    background: "#9923FA",
    border: "1px solid #E9EFF4",
    borderRadius: "4px",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  taco_text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    height: "48px",
  },
  welcome_text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "30px",
    lineHeight: "35px",
    color: "#252525",
  },
  mainImage: {
    backgroundImage: "url(" + MainImage + ")",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
}));

export default function SignInAppSumo(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loginResponse = useSelector((state) => state.login);
  const appSumoRegisterResponse = useSelector((state) => state.appSumpRegister);
  const fbLoginResponse = useSelector((state) => state.fbLogin);

  const [a, setA] = React.useState("");
  const [source, setSource] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setemail] = React.useState("");
  const [Message, setMessage] = React.useState("");
  const [fbEmail, setfbEmail] = React.useState("");
  const [checkState, setcheckState] = React.useState(false);
  const [Title, setTitle] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [withoutEmail, setwithoutEmail] = React.useState(true);
  const [Verify, setVerify] = React.useState("false");
  const [isLoaded, setIsLoaded] = React.useState(false);

  const [checkLogin, setcheckLogin] = React.useState(false);
  // const [FbEmail, setFbEmail] = React.useState("");
  var AccessToken;
  var getFacebookResponse = [];
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: true, // enable logs
  };
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID, { debug: true });
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, options);

  const [OpenState, setOpen] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState1, setOpen1] = React.useState({
    open1: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState2, setOpen2] = React.useState({
    open2: false,
    vertical: "top",
    horizontal: "center",
  });

  const [OpenState3, setOpen3] = React.useState({
    open3: false,
    vertical: "top",
    horizontal: "center",
  });

  const [OpenState4, setOpen4] = React.useState({
    open4: false,
    vertical: "top",
    horizontal: "center",
  });

  const [OpenState5, setOpen5] = React.useState({
    open5: false,
    vertical: "top",
    horizontal: "center",
  });

  const [OpenState6, setOpen6] = React.useState({
    open6: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = OpenState;
  const { open1 } = OpenState1;
  const { open2 } = OpenState2;
  const { open3 } = OpenState3;
  const { open4 } = OpenState4;
  const { open5 } = OpenState5;
  const { open6 } = OpenState6;
  var searchQuery = new URLSearchParams(window.location.search).get("redirect");

  if (searchQuery !== null && searchQuery !== "null") {
    searchQuery = searchQuery.replace("?", "&");
  }

  axios.defaults.withCredentials = true;
  delete axios.defaults.headers.common["Authorization"];

  var value = process.env.REACT_APP_WEATHER_API_KEY;
  var FB_AppId = process.env.REACT_APP_FACEBOOK_APP_ID;

  axios.defaults.headers.common["AppID"] = value;
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const onChangeEmail = (event) => {
    setemail(event.target.value);
  };

  const onChangeUserName = (event) => {
    setName(event.target.value);
  };

  const onChangeFbEmail = (event) => {
    setfbEmail(event.target.value);
    if (
      event.target.value.includes("@") &&
      event.target.value.includes(".com")
    ) {
      setcheckState(true);
    } else {
      setcheckState(false);
    }
  };

  const onChangePassword = (event) => {
    setpassword(event.target.value);
  };

  const onClickLogin = useCallback((data) => {
    // dispatch(Actions.appSumoSignUpAction(data));
  });

  const onClickAppSumoRegister = useCallback((data) => {
    dispatch(Actions.appSumoSignUpAction(data));
  });

  const onClickFbLogin = useCallback((data) => {
    dispatch(Actions.fbLoginAction(data));
  });

  const handleClick = (newState) => {
    setOpen({ open: true, ...newState });
  };

  const handleClick1 = (newState1) => {
    setOpen1({ open1: true, ...newState1 });
  };

  const handleClick2 = (newState2) => {
    setOpen2({ open2: true, ...newState2 });
  };

  const handleClick3 = (newState3) => {
    setOpen3({ open3: true, ...newState3 });
  };

  const handleClick4 = (newState4) => {
    setOpen4({ open4: true, ...newState4 });
  };

  const handleClick5 = (newState5) => {
    setOpen5({ open5: true, ...newState5 });
  };

  const handleClick6 = (newState6) => {
    setOpen6({ open6: true, ...newState6 });
  };

  const handleClose = () => {
    setOpen({ ...OpenState, open: false });
    setOpen1({ ...OpenState1, open1: false });
    setOpen2({ ...OpenState2, open2: false });
    setOpen3({ ...OpenState3, open3: false });
    setOpen4({ ...OpenState4, open4: false });
    setOpen5({ ...OpenState5, open5: false });
    setOpen6({ ...OpenState6, open6: false });
  };

  const responseFacebook = (response) => {
    if (response.status !== "unknown") {
      let data = {
        token: response.accessToken,
        userDetails: response,
      };

      onClickFbLogin(data);
      // setemail("");
      // getFacebookResponse.push(response);

      // AccessToken = response.accessToken;
    }

    // if (!response.email) {
    //   setwithoutEmail(false);
    // }
  };

  const onFacebook = () => {
    ReactGA.event({
      category: "sso",
      action: "continueWithFacebook",
      label: "continueWithFacebook",
    });
  };

  const clickProceed = () => {
    // getFacebookResponse.push({ email: email });
  };

  const getLoginUi = () => {
    setcheckLogin(true);
    ReactGA.event({
      category: "sso",
      action: "loginWithEmail",
      label: "loginWithEmail",
    });
  };

  const getMainUi = () => {
    setcheckLogin(false);
    ReactGA.event({
      category: "sso",
      action: "Back",
      label: "loginWithEmail",
    });
  };

  const Terms = () => {
    ReactGA.event({
      category: "sso",
      action: "Terms",
      label: "Terms",
    });
  };

  const Privacy = () => {
    ReactGA.event({
      category: "sso",
      action: "Privacy",
      label: "Privacy",
    });
  };

  useEffect(() => {
    onClickLogin();
    if (searchQuery !== "null" && searchQuery !== null) {
      var parser = new URL(`${searchQuery}`);
      if (
        parser.host == "dev.creator.flutin.com" ||
        parser.host == "creator.flutin.com" ||
        parser.host == "localhost:4200"
      ) {
        setTitle(process.env.REACT_APP_CREATOR);
      } else if (
        parser.host == "dev.live.flutin.com" ||
        parser.host == "live.flutin.com" ||
        parser.host == "localhost:4201"
      ) {
        setTitle(process.env.REACT_APP_LIVE);
      } else {
        setTitle("");
      }
    }
  }, []);

  useEffect(() => {
    const params = queryString.parse(props.location.search);
    setA(params.a);
    setSource("params.source");
    var url = process.env.REACT_APP_WEATHER_API_URL;

    var value = process.env.REACT_APP_WEATHER_API_KEY;
    var FB_AppId = process.env.REACT_APP_FACEBOOK_APP_ID;

    axios.defaults.headers.common["AppID"] =
      "57b14639-aeff-4cba-85a9-9b183ed72924";
    axios
      .get(
        `${url}/v1/sso/appSumo/resolveUser?a=${params.a}&source=${params.source}`
      )
      .then((response) => {
        setemail(response.data.data.email);
      })
      .catch((error) => {
        if (error.error) {
          handleClick6({ vertical: "top", horizontal: "center" });
        }
      });
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (loginResponse.success != false) {
      if (
        loginResponse.data.success == true &&
        loginResponse.data.verified == true
      ) {
        handleClick({ vertical: "top", horizontal: "center" });
        if (searchQuery !== "null" && searchQuery !== null) {
          var parser = new URL(`${searchQuery}`);
          ReactGA.event({
            category: "sso",
            action: "SignIn successfully",
            label: "SignIn-Automatic",
          });
          window.location = `${parser.origin}/tokenAuth/verify?redirect=${searchQuery}&token=${loginResponse.data.data.token}`;
        }
      } else if (
        loginResponse.data.success == true &&
        loginResponse.data.verified == false
      ) {
        if (searchQuery !== "null" && searchQuery !== null) {
          var parser = new URL(`${searchQuery}`);
          if (
            parser.host == "dev.live.flutin.com" ||
            parser.host == "live.flutin.com" ||
            parser.host == "localhost:4201"
          ) {
            ReactGA.event({
              category: "sso",
              action: "SignIn successfully",
              label: "SignIn-Live-Email",
            });
            handleClick({ vertical: "top", horizontal: "center" });

            var parser = new URL(`${searchQuery}`);

            window.location = `${parser.origin}/tokenAuth/verify?redirect=${searchQuery}&token=${loginResponse.data.data.token}`;
          } else {
            if (loginResponse.data.success == true) {
              ReactGA.event({
                category: "sso",
                action: "SignIn successfully",
                label: "SignIn-Creator-Email",
              });
              setMessage(loginResponse.data.message);
              handleClick4({ vertical: "top", horizontal: "center" });
            }
            getLinkAgain();
          }
        } else {
          ReactGA.event({
            category: "sso",
            action: "SignIn successfully",
            label: "SignIn-Accounts-Email",
          });
          checkEmailVerify();
        }
      }
    }
    if (
      loginResponse.success == false &&
      loginResponse.error.success == true &&
      loginResponse.error.data != undefined
    ) {
      handleClick1({ vertical: "top", horizontal: "center" });
    }
  }, [loginResponse]);

  const checkEmailVerify = () => {
    if (
      loginResponse.data.success == true &&
      loginResponse.data.verified == true
    ) {
      handleClick({ vertical: "top", horizontal: "center" });
    } else if (
      loginResponse.data.success == true &&
      loginResponse.data.verified == false
    ) {
      if (loginResponse.data.success == true) {
        setMessage(loginResponse.data.message);
        handleClick4({ vertical: "top", horizontal: "center" });
      }
      getLinkAgain();
    } else handleClick({ vertical: "top", horizontal: "center" });
  };

  const getLinkAgain = () => {
    if (searchQuery !== "null" && searchQuery !== null) {
      props.history.push("/getLink?redirect=" + searchQuery);
    } else {
      props.history.push("/getLink");
    }
    loginResponse.success = false;
  };

  useEffect(() => {
    if (fbLoginResponse.success != false) {
      if (fbLoginResponse.data.success == true) {
        handleClick({ vertical: "top", horizontal: "center" });
        if (searchQuery !== "null" && searchQuery !== null) {
          var parser = new URL(`${searchQuery}`);

          if (
            parser.host == "dev.live.flutin.com" ||
            parser.host == "live.flutin.com" ||
            parser.host == "localhost:4201"
          ) {
            if (fbLoginResponse.data.signUp == true) {
              ReactGA.event({
                category: "sso",
                action: "SignUp successfully",
                label: "SignUp-Live-Facebook",
              });
              ReactPixel.track("CompleteRegistration", {
                content_category: "Facebook-Live",
              });
            } else {
              ReactGA.event({
                category: "sso",
                action: "SignIn successfully",
                label: "SignIn-Live-Facebook",
              });
            }
          } else {
            if (fbLoginResponse.data.signUp == true) {
              ReactGA.event({
                category: "sso",
                action: "SignUp successfully",
                label: "SignUp-Creator-Facebook",
              });
              ReactPixel.track("CompleteRegistration", {
                content_category: "Facebook-Creator",
              });
            } else {
              ReactGA.event({
                category: "sso",
                action: "SignIn successfully",
                label: "SignIn-Creator-Facebook",
              });
            }
          }

          window.location = `${parser.origin}/tokenAuth/verify?redirect=${searchQuery}&token=${fbLoginResponse.data.data.token}`;
        } else {
          if (fbLoginResponse.data.signUp == true) {
            ReactGA.event({
              category: "sso",
              action: "SignUp successfully",
              label: "SignUp-Accounts-Facebook",
            });
            ReactPixel.track("CompleteRegistration", {
              content_category: "Facebook",
            });
          } else {
            ReactGA.event({
              category: "sso",
              action: "SignIn successfully",
              label: "SignIn-Accounts-Facebook",
            });
          }
        }
      }
    }
    if (
      fbLoginResponse.success == false &&
      fbLoginResponse.error.success == true &&
      fbLoginResponse.error.data != undefined
    ) {
      handleClick3({ vertical: "top", horizontal: "center" });
    }
  }, [fbLoginResponse]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    var credentials = btoa(email + ":" + password);
    var value = process.env.REACT_APP_WEATHER_API_KEY;
    const data = {
      username: email,
      password: password,
      name: name,
      a: a,
    };

    var basicAuth = credentials;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Authorization"] = "Basic " + basicAuth;
    axios.defaults.headers.common["AppID"] = value;

    if (email == "" || password == "" || name == "") {
      handleClick2({ vertical: "top", horizontal: "center" });
    } else {
      ReactGA.event({
        category: "sso",
        action: "signIn",
        label: "signIn",
      });
      await onClickAppSumoRegister(data);
    }
  };

  useEffect(() => {
    if (appSumoRegisterResponse.success == true) {
      const response = appSumoRegisterResponse.data.data;
      // const CREATORHOST = process.env.REACT_APP_CREATOR_HOST;
      const CREATORHOST = "https://creator.flutin.com";
      const redirectURL = `${CREATORHOST}/tokenAuth/verify?redirect=${response.redirect}&token=${response.token.token}&arbitrary=${response.arbitrary}&source=${response.source}`;  
      window.location.replace(redirectURL);
    } else if (
      appSumoRegisterResponse.error &&
      !appSumoRegisterResponse.success
    ) {
      handleClick6({ vertical: "top", horizontal: "center" });
    }
  }, [appSumoRegisterResponse]);

  const handleForget = () => {
    ReactGA.event({
      category: "sso",
      action: "forgotPassword",
      label: "forgotPassword",
    });
    if (searchQuery != null) {
      props.history.push("/Email?redirect=" + searchQuery);
    } else {
      props.history.push("/Email");
    }
  };

  const handleSignup = () => {
    ReactGA.event({
      category: "sso",
      action: "registerWithEmail",
      label: "registerWithEmail",
    });
    if (searchQuery != null) {
      props.history.push("/Sign_up?redirect=" + searchQuery);
    } else {
      props.history.push("/Sign_up");
    }
  };

  const clickLogo = () => {
    if (searchQuery !== null) {
      props.history.push("/?redirect=" + searchQuery);
    } else {
      props.history.push("/");
    }
    ReactGA.event({
      category: "sso",
      action: "logo",
      label: "logo",
    });
  };

  // const onLogout = () => {
  //   window.FB.logout(AccessToken);
  // };

  // const getLikes = () => {
  //   window.FB.login(
  //     function (response) {
  //     },
  //     {
  //       scope: "email",
  //       auth_type: "rerequest",
  //     }
  //   );
  // };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifycontent="center"
        alignItems="center"
        style={{ backgroundColor: "white", height: "100vh" }}
      >
        <Grid item md={6} lg={7} xs={12}>
          <Container
            className={classes.container_color}
            component="main"
            maxWidth="sm"
          >
            <CssBaseline />
            <div>
              <div className={classes.avatar} display="flex">
                <img
                  style={{ width: "130px" }}
                  display="inline"
                  src={FlutinLogoWithCamera}
                />
                <span style={{ paddingLeft: "3%", paddingRight: "3%" }}>
                  with
                </span>
                <img
                  style={{ width: "130px" }}
                  display="inline"
                  src={AppSumoLogo}
                />
              </div>

              <div className={classes.taco_background}>
                <div className={classes.taco_text}>
                  Taco `bout a great deal, right?
                  <img
                    style={{ width: "25px", verticalAlign: "middle" }}
                    display="inline"
                    src={TacoLogo}
                  />
                </div>
              </div>

              <div className={classes.sub_container}>
                <Typography
                  className={classes.title}
                  component="h2"
                  variant="h5"
                >
                  <b>{Title}</b>
                </Typography>
                <Typography
                  className={classes.welcome_text}
                  component="h1"
                  variant="h6"
                >
                  Welcome Sumo-ling!
                </Typography>
                <Typography
                  className={classes.Sign_in}
                  component="h1"
                  variant="h6"
                >
                  Please set your password down below to get started with
                  Flutin.
                </Typography>

                <ValidatorForm className={classes.form}>
                  {withoutEmail ? (
                    <div>
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="name"
                        value={name}
                        type="text"
                        onChange={onChangeUserName}
                        label="Name"
                        name="name"
                        autoComplete="name"
                        validators={["required"]}
                        errorMessages={[
                          "Name is required",
                          "User Name is not valid",
                        ]}
                        autoFocus
                      />
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        value={email}
                        type="email"
                        onChange={onChangeEmail}
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "this field is required",
                          "email is not valid",
                        ]}
                        disabled
                        autoFocus
                      />
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={password}
                        onChange={onChangePassword}
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                      />

                      {/* <Grid container className={classes.display}>
                    <Grid item style={{ textAlign: "right" }}>
                      <Typography onClick={handleForget} className={classes.forget}>
                        Forgot Your password?
                      </Typography>
                    </Grid>
                  </Grid> */}

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                      >
                        Sign Up
                      </Button>
                      {/* <button onClick={onLogout}>Logout</button>
           <button onClick={getLikes}>Likes</button> */}

                      {/* <Grid container className={classes.display}>
                    <Grid item>
                      <Typography onClick={handleSignup} className={classes.align_signup}>
                        Don't have an account?
                        <b className={classes.color}>Sign Up</b>
                      </Typography>
                    </Grid>
                  </Grid> */}

                      {/* <Grid container className={classes.display}>
                    <Grid item>
                      <Typography onClick={getMainUi} className={classes.align_Back}>
                        <div>
                          <svg
                            style={{ marginTop: "1px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="18"
                            fill="#6200EE"
                            class="bi bi-chevron-left"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                            />
                          </svg>
                        </div>
                        <b className={classes.color}>Back</b>
                      </Typography>
                    </Grid>
                  </Grid> */}
                    </div>
                  ) : (
                    <div>
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        value={fbEmail}
                        type="email"
                        onChange={onChangeFbEmail}
                        label="Email Address"
                        name="email"
                        autoComplete="off"
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "this field is required",
                          "email is not valid",
                        ]}
                        autoFocus
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={clickProceed}
                        disabled={!checkState}
                      >
                        Proceed
                      </Button>
                    </div>
                  )}
                </ValidatorForm>
              </div>
            </div>
            <Snackbar
              open={open}
              key="topcenter"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="success">
                Login success!
              </Alert>
            </Snackbar>
            <Snackbar
              open={open1}
              key="topcenter1"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {loginResponse.error.data}
              </Alert>
            </Snackbar>
            <Snackbar
              open={open2}
              key="topcenter2"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="info">
                Please fill All the fields
              </Alert>
            </Snackbar>
            <Snackbar
              open={open3}
              key="topcenter3"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {fbLoginResponse.error.data}
              </Alert>
            </Snackbar>
            <Snackbar
              open={open4}
              key="topcenter4"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="info">
                {Message}
              </Alert>
            </Snackbar>
            <Snackbar
              open={open5}
              key="topcenter5"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {/* Your email is not verified. Please verify your email */}
                {appSumoRegisterResponse.error}
              </Alert>
            </Snackbar>
            <Snackbar
              open={open6}
              key="topcenter6"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {/* Your email is not verified. Please verify your email */}
                {appSumoRegisterResponse.error}
              </Alert>
            </Snackbar>
          </Container>
        </Grid>

        <Hidden smDown>
          <Grid item md={6} lg={5} className={classes.mainImage}></Grid>
        </Hidden>
      </Grid>

      {/*
      {!checkLogin ? (
        <Container className={classes.terms_container} component="main" maxWidth="xs">
          <Grid container className={classes.security}>
            <Grid item xs={6}>
              <div>
                <img className={classes.lock} src={lock}></img> Secure
              </div>
            </Grid>
            <Grid className={classes.privacy} item xs={6}>
              <div className={classes.cursor}>
                <a
                  onClick={Privacy}
                  target="_blank"
                  style={{ textDecoration: "none", color: "#767676" }}
                  href="https://creator.flutin.com/privacy"
                >
                  Privacy
                </a>{" "}
                &nbsp; &nbsp;{" "}
                <a
                  onClick={Terms}
                  target="_blank"
                  style={{ textDecoration: "none", color: "#767676" }}
                  href="https://flutin.com/terms/"
                >
                  Terms
                </a>
              </div>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <div></div>
      )}
      */}
    </div>
  );
}
