import axios from 'axios';
var url = process.env.REACT_APP_WEATHER_API_URL
export const testingService = () => {

    return new Promise((resolve, reject) => {
        axios.get(`${url}/v1/sso/profile`)
        // console.log('response',response)

        .then( response => {

            if(response.data.success){
                resolve(response.data.data)
                console.log('response',response)
            // response.headers['set-cookie']


            } 

            else reject(response.data.error)
        })
        .catch(err => {
            reject(err.message)
            console.log('response',err)
        })
    })
}