import * as Actions from "../actions";

const initialState = { success: false, error: { success: true, }, data: null };

const emailVerifyLink = function (state = initialState, action) {
  switch (action.type) {
    case Actions.EMAIL_VERIFY_LINK_SUCCESS: {
      return {
        ...initialState, success: true, data: action.payload,        // interim_token:action.token
      };
    }
    case Actions.EMAIL_VERIFY_LINK_ERROR: { return { success: false, error: { success: true, data: action.payload1 }, }; }
    case Actions.OTP_VERIFY_SUCCESS: return { ...action };
    case Actions.OTP_VERIFY_ERROR: return { ...action };

    default: {
      return state;
    }
  }
};

export default emailVerifyLink;
