import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import ReactGA from "react-ga4";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "../store/actions/index";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Box from "@material-ui/core/Box";
import Flutin from "./Images/Flutin.svg";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import RefreshIcon from "@material-ui/icons/Refresh";
import Hidden from "@material-ui/core/Hidden";
import MainImage from "./Images/right-img.png";
import MainImageMobile from "./Images/Mobile-img.png"
import lock from "./Images/lock.svg";
import Line from "./Images/Line.svg";
import OtpInput from 'react-otp-input';
// import withReducer from "app/store/withReducer";

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper1: {
    // marginTop: theme.spacing(30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    // marginTop: theme.spacing(8),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(6, 0, 2),
    background: "#6a80f0",
    borderRadius: "5px",
    color: "white",
    height: "45px",
    width: "296px",
    fontWeight: 800,
  },
  container_color: {
    backgroundColor: "white",
    paddingLeft: "32px",
    paddingRight: "32px",
  },
  title: {
    color: "#767676",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "26px",
    marginTop: "12px",
  },
  align_signin: {
    textAlign: "center",
    cursor: "pointer",
    fontSize: "14px",
    display: "block",
    color: "#9A9A9A",
    marginBottom: "24px",
  },
  display: {
    display: "block",
  },
  color: {
    color: "#6200EE",
  },
  text_style: {
    marginTop: "16px",
    textAlign: "center",
  },
  resend: {
    marginRight: theme.spacing(1),
  },
  loader: {
    marginRight: theme.spacing(1),
    color: "white",
    width: "22px !important",
    height: "22px !important",
  },
  gradient_background: {
    "@media (max-width: 767px)": {
      backgroundImage: "url(" + MainImageMobile + ")",
      backgroundSize: "cover",
      position: "absolute",
      width: "100%",
      top: "-14px",
      height: "320px",
    },
  },
  mainHeader: {
    marginTop: "77px",
    "@media (max-width: 767px)": {
      marginTop: "196px",
    },
  },
  mainHeader1: {
    fontFamily: "Exo",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "60px",
    textAlign: "center",
    color: "#FFFFFF",
    "@media (max-width: 767px)": {
      fontSize: "18px",
      lineHeight: "28px",
    },
  },
  mainHeader2: {
    fontFamily: "Exo",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#FFFFFF",
    "@media (max-width: 767px)": {
      fontSize: "18px",
      lineHeight: "28px",
    },
  },
  mainImage: {
    backgroundImage: "url(" + MainImage + ")",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  mainGrid: {
    backgroundColor: "white",
    "@media (min-width: 767px)": {
      height: "100vh"
    },
  },
  grid: {
    "@media (max-width: 767px)": {
      zIndex: 1,
      position: "relative",
      top: "235px",
      padding: "16px"
    },
  }
}));

export default function GetEmailVerifyLink(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const emailVerifyLink = useSelector((state) => state.emailVerifyLink);
  const getEmailReducer = useSelector((state) => state.getEmailReducer);
  const logoutResponse = useSelector((state) => state.logout);
  const registerResponse = useSelector((state) => state.register);

  const [showLoader, setshowLoader] = React.useState(false);

  const [Message, setMessage] = React.useState("");
  const [getEmail, setgetEmail] = React.useState("");
  const [alertSeverity, setalertSeverity] = useState('success');
  const updateAlerteverity = (newAlertSeverity) => { setalertSeverity(newAlertSeverity); };
  const searchQuery = new URLSearchParams(window.location.search).get("redirect");

  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID, { debug: false });

  axios.defaults.withCredentials = true;
  var value = process.env.REACT_APP_WEATHER_API_KEY;
  axios.defaults.headers.common["AppID"] = value;
  delete axios.defaults.headers.common["Authorization"];

  const [OpenState, setOpen] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = OpenState;

  const [otp, setOtp] = React.useState('');

  function Alert(props) { return <MuiAlert elevation={6} variant="filled" {...props} />; }
  const handleClick = (newState) => { setOpen({ open: true, ...newState }); };
  const handleClose = () => { setOpen({ ...OpenState, open: false }); };

  useEffect(() => {
    if (getEmailReducer.success == true) {
      if (getEmailReducer.data.success == true) {
        setgetEmail(getEmailReducer.data.email);
      }
    } else {
      dispatch(Actions.getEmailAction());
    }
  }, [getEmailReducer]);

  useEffect(() => {
    if (logoutResponse.success == true) {
      if (searchQuery !== "null" && searchQuery !== null) props.history.push("/?redirect=" + searchQuery);
      else props.history.push("/");
      logoutResponse.success = false;
    }
    if (logoutResponse.success == false && logoutResponse.error.success == true) {
      if (searchQuery !== "null" && searchQuery !== null) props.history.push("/?redirect=" + searchQuery);
      else props.history.push("/");
      logoutResponse.error.success = false;
    }
  }, [logoutResponse]);

  useEffect(() => {
    switch (emailVerifyLink.type) {
      case "OTP_VERIFY_ERROR":
        var errorMessage = 'Invalid Code';
        if (emailVerifyLink.payload && emailVerifyLink.payload.error) errorMessage = emailVerifyLink.payload.error.message;
        if (emailVerifyLink.payload && emailVerifyLink.payload.message) errorMessage = emailVerifyLink.payload.message;
        setalertSeverity('error');
        setMessage(errorMessage);
        handleClick({ vertical: "top", horizontal: "center" });
        setshowLoader(false);
        setOtp('');
        ReactGA.event({ category: "sso", action: "OTP Verification Failed", label: "OTP Verification Failed", });
        break;
      case "OTP_VERIFY_SUCCESS":
        var errorMessage = 'OTP Verified';
        if (emailVerifyLink.payload && emailVerifyLink.payload.error) errorMessage = emailVerifyLink.payload.error.message;
        if (emailVerifyLink.payload && emailVerifyLink.payload.message) errorMessage = emailVerifyLink.payload.message;
        setalertSeverity('success');
        setMessage(errorMessage);
        handleClick({ vertical: "top", horizontal: "center" });
        setshowLoader(false);
        setOtp('');
        ReactGA.event({ category: "sso", action: "OTP Verifed Successfully", label: "OTP Verifed Successfully", });
        if (searchQuery !== "null" && searchQuery !== null) props.history.push("/?redirect=" + searchQuery);
        else window.location.replace(process.env.REACT_APP_CREATOR_HOST);
        break;

      default:
        break;
    }
    if (emailVerifyLink.success == true) {
      if (
        emailVerifyLink.data.success == true &&
        emailVerifyLink.data.data !== null &&
        emailVerifyLink.data.data !== "null" &&
        emailVerifyLink.data.data !== undefined
      ) {
        setMessage(emailVerifyLink.data.data.message);
        handleClick({ vertical: "top", horizontal: "center" });
        setshowLoader(false);
        ReactGA.event({ category: "sso", action: "Verification Link Sent Successfully", label: "Verification Link Sent Successfully", });
      }
    }
  }, [emailVerifyLink]);


  const clickLogo = () => {
    if (searchQuery !== null) props.history.push("/?redirect=" + searchQuery);
    else props.history.push("/");
    ReactGA.event({ category: "sso", action: "logo", label: "logo", });
  };

  const onLogout = useCallback((data) => { dispatch(Actions.logoutAction(data)) });

  const anotherAccount = () => {
    let data = { logout: "logout", };
    onLogout(data);
  };

  const sendLink = useCallback(() => {
    setalertSeverity('success');
    setshowLoader(true);
    ReactGA.event({ category: "sso", action: "Send Verification Link Again", label: "Send Verification Link Again", });
    let data = { redirect: searchQuery != null || searchQuery != "null" ? `${searchQuery}` : null, };
    dispatch(Actions.getEmailVerifyLinkAction(data));
    // handleClick({ vertical: "top", horizontal: "center" });
  });

  const submitOTP = useCallback((value) => {
    if (value) {
      if (value.length === 6) {
        setalertSeverity('success');
        setshowLoader(true);
        ReactGA.event({ category: "sso", action: "Submit OTP", label: "Submit OTP", });
        dispatch(Actions.submitOTP(value));
      } else {
        console.error("OTP LENGTH LESS THAN 6", value, value.length)
      }
    } else {
      console.error("OTP NOT FOUND")
    }
  });

  const otpChange = useCallback((value) => {
    setOtp(value);
    if (value.length === 6) submitOTP(value);
  })

  return (
    <div>
      <div className={classes.gradient_background}></div>
      <Grid
        container
        direction="row"
        justifycontent="center"
        alignItems="center"
        className={classes.mainGrid}
        style={{}}
      >
        <Grid item md={6} lg={7} xs={12} className={classes.grid}>
          <Container
            className={classes.container_color}
            component="main"
            maxWidth="xs"
          >
            <CssBaseline />
            <div className={classes.paper}>
              <div className={classes.avatar}>
                <img
                  style={{ cursor: "pointer", width: "130px" }}
                  onClick={clickLogo}
                  src={Flutin}
                  alt=""
                />
              </div>
              <div className={classes.text_style}>
                <div style={{ fontSize: "22px" }}> Please enter <b > 6-digit </b>code</div>
                <div style={{ fontSize: "18px" }}> sent to your email id "{getEmail}"</div>

                <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', padding: '2rem' }}>
                  <OtpInput
                    value={otp}
                    onChange={otpChange}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    inputType='tel'
                    inputStyle={{ height: '4rem', width: '3rem', fontSize: 22 }}
                  />
                </div>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={submitOTP}
                  disabled={(otp.length !== 6 || showLoader)}
                  size="large"
                  style={{ margin: '1rem 0' }}
                >
                  Submit
                </Button>
                <div style={{ fontSize: "16px", color: "#9A9A9A" }}>
                  Please check your promotion or spam folders if you don't find the
                  email in your primary inbox.
                </div>
              </div>

              <div style={{ padding: "1rem" }}>Didn't get the email? <span style={{ cursor: 'pointer' }} className={classes.color} onClick={sendLink}> Click here to send again.</span></div>

              <Grid container className={classes.display}>
                <Grid item>
                  <Typography
                    onClick={anotherAccount}
                    className={classes.align_signin}
                  >
                    <b className={classes.color}>Sign In/SignUp</b> to another
                    account
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Container>
          <Snackbar open={open} key="topcenter" autoHideDuration={4000} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alertSeverity}> {Message} </Alert>
          </Snackbar>
        </Grid>
        <Hidden smDown>
          <Grid item md={6} lg={5} className={classes.mainImage}></Grid>
        </Hidden>
      </Grid>
    </div>
  );
}
