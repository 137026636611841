import * as Actions from '../actions';

const initialState = {
    success: false,
    error  : null,
    msg: null

};

const register = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.REGISTER_SUCCESS:
        {
            return {
                ...initialState,
                success: true,
                data: action.payload,
                msg: action.msg
                // access_token:action.token
            };
        }
        case Actions.REGISTER_ERROR:
        {
            return {
                success: false,
                error  : action.payload
            };
        }
        default:
        {
            return state
        }
    }
};

export default register;