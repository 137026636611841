import React from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import SignIn from "./components/Login";
import GetEmailVerifyLink from "./components/getEmailVerifyLink";
import Logout from "./components/Logout";
import ForgetPassword from "./components/forgetPass";
import ForgetPasswordEmail from "./components/ForgetPassEmail";
import SignUp from "././components/Register";
import EmailVerification from "./components/VerifyEmail";
import Testing from "./components/Testing";
import ChangePassword from "./components/changePass";
import SignInAppSumo from "./components/loginAppSumo";
import Zapier from "./components/ZapierLogin";
import Pabbly from "./components/PabblyLogin";
import Ifttt from "./components/IftttLogin";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Route exact path="/" component={SignIn} />
        <Route exact path="/null" render={() => <Redirect to="/" />} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/getLink" component={GetEmailVerifyLink} />
        <Route exact path="/Email" component={ForgetPasswordEmail} />
        <Route path="/ChangePwd" component={ForgetPassword} />
        <Route exact path="/changePassword" component={ChangePassword} />
        <Route exact path="/Sign_up" component={SignUp} />
        <Route exact path="/verifyEmail" component={EmailVerification} />
        <Route path="/testing" component={Testing} />
        <Route exact path="/appSumo/login" component={SignInAppSumo} />
        <Route path="/v1/sso/zapier" component={Zapier} />
        <Route path="/v1/sso/pabbly" component={Pabbly} />
        <Route path="/v1/sso/ifttt" component={Ifttt} />
      </BrowserRouter>
    </div>
  );
}

export default App;
