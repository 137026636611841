import axios from 'axios';
var url = process.env.REACT_APP_WEATHER_API_URL

export const VerifyEmailService = (data) => {
    return new Promise((resolve, reject) => {
        // `v1/${ARTIST}/profileOverview?uid=${uid}`
        axios.get(`${url}/v1/sso/verifyEmail?user=${data}`)
        .then(response => {

            if ( response.data )
            {
                resolve(response);
            }
            else
            {
                reject(response.data.error);

            }
        })
        .catch( err => {
            reject(err)
        })
        ;
    });
};
