import axios from "axios";
var url = process.env.REACT_APP_WEATHER_API_URL;

export const getEmail = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}/v1/sso/getEmail`)
      .then((response) => {
        if (response.data.success == true) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
