import { combineReducers } from "redux";
import login from "./login.reducer";
import fbLogin from "./fbLogin.reducer";
import logout from "./logout.reducer";
import ForgetPass from "./forgetPass.reducer";
import register from "./register.reducer";
import verifyEmail from "./verifyemail.reducer";
import testingReducer from "./testing.reducer";
import ForgetPassEmail from "./ForgetPassEmail.reducer";
import ChangePass from "./changePass.reducer";
import forgotPasswordLink from "./forgotPasswordLink.reducer";
import emailVerifyLink from "./emailVerifyLink.reducer";
import getEmailReducer from "./getEmail.reducer";
import appSumpRegister from "./appSumoRegister.reducer";
import zapierLoginReducer from "./zapier.reducer";
import pabblyLoginReducer from "./pabbly.reducer";
import iftttLoginReducer from "./ifttt.reducer";

const reducer = combineReducers({
  login,
  logout,
  ForgetPass,
  register,
  verifyEmail,
  testingReducer,
  ForgetPassEmail,
  ChangePass,
  fbLogin,
  emailVerifyLink,
  forgotPasswordLink,
  getEmailReducer,
  appSumpRegister,
  zapierLoginReducer,
  pabblyLoginReducer,
  iftttLoginReducer
});

export default reducer;
