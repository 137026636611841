import * as Actions from "../actions";

const initialState = {
  success: false,
  error: {
    success: false,
  },
  data: {
    msg: null,
  },
};

const logout = function (state = initialState, action) {
  switch (action.type) {
    case Actions.LOGOUT_SUCCESS: {
      return {
        ...initialState,
        success: true,
        data: action.payload,
        // interim_token:action.token
      };
    }
    case Actions.LOGOUT_ERROR: {
      return {
        success: false,
        error: {
          success: true,
          data: {
            mgs: null,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default logout;
