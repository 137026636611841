import React, { useEffect, useCallback } from "react";
import axios from "axios";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "../store/actions/index";
// import withReducer from "app/store/withReducer";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
// import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";

// import Flutin from "./Images/Flutin_mini.png";
import facebook from "./Images/facebook.svg";
import EmailIcon from "./Images/EmailIcon.svg";
import WhiteEmailIcon from "./Images/WhiteEmailIcon.svg";
import lock from "./Images/lock.svg";
import Line from "./Images/Line.svg";
import Flutin from "./Images/Flutin.svg";
import FlutinLogoWithCamera from "./Images/FlutinLogoWithCamera.svg";
import MainImage from "./Images/right-img.png";
import PabblyLogo from "./Images/pabbly-logo.png";
import "../style/ZapierLogin.scss";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper1: {
    marginTop: theme.spacing(30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  lock: {
    marginBottom: "-1px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  container_color: {
    backgroundColor: "white",
    paddingLeft: "32px",
    paddingRight: "32px",
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    background: "#9923FA",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    color: "white",
    height: "60px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "0.02em",
    color: "#FFFFFF",
  },
  forget: {
    cursor: "pointer",
    fontSize: "14px",
    color: "#6200EE",
    alignItems: "right",
    fontWeight: 500,
  },
  display: {
    display: "block",
  },

  align_signup: {
    textAlign: "center",
    cursor: "pointer",
    fontSize: "14px",
    display: "block",
    color: "#9A9A9A",
    marginBottom: "24px",
  },
  color: {
    color: "#6200EE",
  },
  Sign_in: {
    marginTop: theme.spacing(3),
    color: "#9D9D9D",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
  },

  title: {
    color: "#767676",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
  },

  facebook_icon: {
    marginBottom: "-8px",
    marginLeft: "18px",
    marginTop: "-2px",
    float: "left",
  },

  email_icon: {
    marginBottom: "-5px",
    marginTop: "1px",
    marginLeft: "18px",
  },

  facebook_btn: {
    padding: "6px 16px",
    fontSize: "0.875rem",
    fontWeight: 900,
    lineHeight: "1.75",
    borderRadius: "4px",
    width: "100%",
    color: "#1665D8",
    background: "#D0E0F7",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    height: "56px",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
    fontFamily: "Roboto",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px",
    },
  },
  sub_container: {
    width: "100%",
    textAlign: "center",
  },
  or_text: {
    marginTop: "15px",
    marginBottom: "15px",
    color: "#969494",
    fontWeight: 900,
    fontSize: "16px",
  },
  email_img: {
    display: "inline-block",
    float: "left",
  },
  login_email: {
    padding: "6px 16px",
    fontSize: "0.875rem",
    fontWeight: 900,
    lineHeight: "1.75",
    borderRadius: "4px",
    width: "100%",
    color: "#6B77FF",
    background: "white",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    height: "56px",
    border: "1px solid #6B77FF",
    boxSizing: "border-box",
    fontFamily: "Roboto",
    fontSize: "18px",
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px",
    },
  },
  register_email: {
    marginBottom: "30px",
    cursor: "pointer",
    padding: "6px 16px",
    fontSize: "0.875rem",
    fontWeight: 900,
    lineHeight: "1.75",
    borderRadius: "4px",
    width: "100%",
    color: "white",
    background: "#122BA9",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    height: "56px",
    boxSizing: "border-box",
    fontFamily: "Roboto",
    fontSize: "18px",
    border: "none",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px",
    },
  },
  login_email_container: {
    marginBottom: "18px",
    textAlign: "center",
  },
  terms_container: {
    background: "#f8f8f8",
    padding: "0px",
    color: "#767676",
    fontWeight: 500,
    fontSize: "14px",
  },
  privacy: {
    textAlign: "right",
  },
  cursor: {
    cursor: "pointer",
  },
  line: {
    marginBottom: "5px",
  },
  security: {
    padding: "8px",
  },
  align_Back: {
    textAlign: "center",
    cursor: "pointer",
    fontSize: "14px",
    display: "block",
    color: "#9A9A9A",
    marginBottom: "24px",
    display: "flex",
    justifyContent: "center",
  },
  taco_background: {
    width: "100%",
    // height: "48px",
    background: "#9923FA",
    border: "1px solid #E9EFF4",
    borderRadius: "4px",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  taco_text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    height: "48px",
  },
  welcome_text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "30px",
    lineHeight: "35px",
    color: "#252525",
  },
  mainImage: {
    backgroundImage: "url(" + MainImage + ")",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
}));

export default function PabblyLogin(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loginResponse = useSelector((state) => state.login);
  const appSumoRegisterResponse = useSelector((state) => state.appSumpRegister);
  const fbLoginResponse = useSelector((state) => state.fbLogin);

  const pabblyLoginResponse = useSelector((state) => state.pabblyLoginReducer);

  const [email, setemail] = React.useState("");
  const [Message, setMessage] = React.useState("");
  const [checkState, setcheckState] = React.useState(false);
  const [Title, setTitle] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [Verify, setVerify] = React.useState("false");
  const [isLoaded, setIsLoaded] = React.useState(false);

  const [checkLogin, setcheckLogin] = React.useState(false);

  var [showLoginPage, setShowLoginPage] = React.useState(true);
  var [allowPermissions, setAllowPermissions] = React.useState(false);
  const [authCode, setAuthCode] = React.useState("");
  var authCode2;
  // const [FbEmail, setFbEmail] = React.useState("");
  var AccessToken;
  var getFacebookResponse = [];
  const reactPixelOptions = { autoConfig: true, debug: true };

  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID, { debug: false });
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, reactPixelOptions);

  const [OpenState, setOpen] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState1, setOpen1] = React.useState({
    open1: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState2, setOpenNameEmailValidation] = React.useState({
    open2: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState3, setOpen3] = React.useState({
    open3: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState4, setOpen4] = React.useState({
    open4: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState5, setOpen5] = React.useState({
    open5: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState6, setOpen6] = React.useState({
    open6: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState7, setOpen7] = React.useState({
    open7: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = OpenState;
  const { open1 } = OpenState1;
  const { open2 } = OpenState2;
  const { open3 } = OpenState3;
  const { open4 } = OpenState4;
  const { open5 } = OpenState5;
  const { open6 } = OpenState6;
  const { open7 } = OpenState7;
  const allQueryParamsInURL = new URLSearchParams(window.location.search);
  const searchQuery = allQueryParamsInURL.get("redirect");

  if (searchQuery !== null && searchQuery !== "null") {
    searchQuery = searchQuery.replace("?", "&");
  }

  axios.defaults.withCredentials = true;
  delete axios.defaults.headers.common["Authorization"];

  var value = process.env.REACT_APP_WEATHER_API_KEY;
  var FB_AppId = process.env.REACT_APP_FACEBOOK_APP_ID;

  axios.defaults.headers.common["AppID"] = value;
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const onChangeEmail = (event) => {
    setemail(event.target.value);
  };

  const onChangePassword = (event) => {
    setpassword(event.target.value);
  };

  const onClickLogin = useCallback((data) => {
    // dispatch(Actions.appSumoSignUpAction(data));
  });

  const registerOnPabbly = useCallback((data, queryParams) => {
    dispatch(Actions.pabblyLoginAction(data, queryParams));
  });

  const onClickFbLogin = useCallback((data) => {
    dispatch(Actions.fbLoginAction(data));
  });

  const handleClick = (newState) => {
    setOpen({ open: true, ...newState });
  };

  const handleClick1 = (newState1) => {
    setOpen1({ open1: true, ...newState1 });
  };

  const openNameEmailValidation = (newState2) => {
    setOpenNameEmailValidation({ open2: true, ...newState2 });
  };

  const handleClick3 = (newState3) => {
    setOpen3({ open3: true, ...newState3 });
  };

  const handleClick4 = (newState4) => {
    setOpen4({ open4: true, ...newState4 });
  };

  const handleClick5 = (newState5) => {
    setOpen5({ open5: true, ...newState5 });
  };

  const handleClick6 = (newState6) => {
    setOpen6({ open6: true, ...newState6 });
  };

  const handleClick7 = (newState7) => {
    setOpen7({ open7: true, ...newState7 });
  };

  const handleClose = () => {
    setOpen({ ...OpenState, open: false });
    setOpen1({ ...OpenState1, open1: false });
    setOpenNameEmailValidation({ ...OpenState2, open2: false });
    setOpen3({ ...OpenState3, open3: false });
    setOpen4({ ...OpenState4, open4: false });
    setOpen5({ ...OpenState5, open5: false });
    setOpen6({ ...OpenState6, open6: false });
    setOpen7({ ...OpenState7, open7: false });
  };

  const Terms = () => {
    ReactGA.event({ category: "sso", action: "Terms", label: "Terms" });
  };

  const Privacy = () => {
    ReactGA.event({ category: "sso", action: "Privacy", label: "Privacy" });
  };

  useEffect(() => {
    onClickLogin();
    document.title = "Login to Flutin with Pabbly";
    if (searchQuery !== "null" && searchQuery !== null) {
      var parser = new URL(`${searchQuery}`);
      if (
        parser.host == "dev.creator.flutin.com" ||
        parser.host == "creator.flutin.com" ||
        parser.host == "localhost:4200"
      ) {
        setTitle(process.env.REACT_APP_CREATOR);
      } else if (
        parser.host == "dev.live.flutin.com" ||
        parser.host == "live.flutin.com" ||
        parser.host == "localhost:4201"
      ) {
        setTitle(process.env.REACT_APP_LIVE);
      } else {
      }
    }
  }, []);

  useEffect(() => {
    const params = queryString.parse(props.location.search);
    var url = process.env.REACT_APP_WEATHER_API_URL;

    var value = process.env.REACT_APP_WEATHER_API_KEY;
    var FB_AppId = process.env.REACT_APP_FACEBOOK_APP_ID;

    axios.defaults.headers.common["AppID"] =
      "57b14639-aeff-4cba-85a9-9b183ed72924";
    axios
      .get(
        `${url}/v1/sso/appSumo/resolveUser?a=${params.a}&source=${params.source}`
      )
      .then((response) => {
        setemail(response.data.data.email);
      })
      .catch((error) => {
        if (error.error) {
          handleClick6({ vertical: "top", horizontal: "center" });
        }
      });
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (loginResponse.success != false) {
      if (
        loginResponse.data.success == true &&
        loginResponse.data.verified == true
      ) {
        handleClick({ vertical: "top", horizontal: "center" });
        if (searchQuery !== "null" && searchQuery !== null) {
          var parser = new URL(`${searchQuery}`);
          ReactGA.event({
            category: "sso",
            action: "SignIn successfully",
            label: "SignIn-Automatic",
          });
          window.location = `${parser.origin}/tokenAuth/verify?redirect=${searchQuery}&token=${loginResponse.data.data.token}`;
        }
      } else if (
        loginResponse.data.success == true &&
        loginResponse.data.verified == false
      ) {
        if (searchQuery !== "null" && searchQuery !== null) {
          var parser = new URL(`${searchQuery}`);
          if (
            parser.host == "dev.live.flutin.com" ||
            parser.host == "live.flutin.com" ||
            parser.host == "localhost:4201"
          ) {
            ReactGA.event({
              category: "sso",
              action: "SignIn successfully",
              label: "SignIn-Live-Email",
            });
            handleClick({ vertical: "top", horizontal: "center" });
            var parser = new URL(`${searchQuery}`);
            window.location = `${parser.origin}/tokenAuth/verify?redirect=${searchQuery}&token=${loginResponse.data.data.token}`;
          } else {
            if (loginResponse.data.success == true) {
              ReactGA.event({
                category: "sso",
                action: "SignIn successfully",
                label: "SignIn-Creator-Email",
              });
              setMessage(loginResponse.data.message);
              handleClick4({ vertical: "top", horizontal: "center" });
            }
          }
        } else {
          ReactGA.event({
            category: "sso",
            action: "SignIn successfully",
            label: "SignIn-Accounts-Email",
          });
        }
      }
    }
    if (
      loginResponse.success == false &&
      loginResponse.error.success == true &&
      loginResponse.error.data != undefined
    ) {
      handleClick1({ vertical: "top", horizontal: "center" });
    }
  }, [loginResponse]);

  useEffect(() => {
    if (fbLoginResponse.success != false) {
      if (fbLoginResponse.data.success == true) {
        handleClick({ vertical: "top", horizontal: "center" });
        if (searchQuery !== "null" && searchQuery !== null) {
          var parser = new URL(`${searchQuery}`);
          if (
            parser.host == "dev.live.flutin.com" ||
            parser.host == "live.flutin.com" ||
            parser.host == "localhost:4201"
          ) {
            if (fbLoginResponse.data.signUp == true) {
              ReactGA.event({
                category: "sso",
                action: "SignUp successfully",
                label: "SignUp-Live-Facebook",
              });
              ReactPixel.track("CompleteRegistration", {
                content_category: "Facebook-Live",
              });
            } else {
              ReactGA.event({
                category: "sso",
                action: "SignIn successfully",
                label: "SignIn-Live-Facebook",
              });
            }
          } else {
            if (fbLoginResponse.data.signUp == true) {
              ReactGA.event({
                category: "sso",
                action: "SignUp successfully",
                label: "SignUp-Creator-Facebook",
              });
              ReactPixel.track("CompleteRegistration", {
                content_category: "Facebook-Creator",
              });
            } else {
              ReactGA.event({
                category: "sso",
                action: "SignIn successfully",
                label: "SignIn-Creator-Facebook",
              });
            }
          }
          window.location = `${parser.origin}/tokenAuth/verify?redirect=${searchQuery}&token=${fbLoginResponse.data.data.token}`;
        } else {
          if (fbLoginResponse.data.signUp == true) {
            ReactGA.event({
              category: "sso",
              action: "SignUp successfully",
              label: "SignUp-Accounts-Facebook",
            });
            ReactPixel.track("CompleteRegistration", {
              content_category: "Facebook",
            });
          } else {
            ReactGA.event({
              category: "sso",
              action: "SignIn successfully",
              label: "SignIn-Accounts-Facebook",
            });
          }
        }
      }
    }
    if (
      fbLoginResponse.success == false &&
      fbLoginResponse.error.success == true &&
      fbLoginResponse.error.data != undefined
    ) {
      handleClick3({ vertical: "top", horizontal: "center" });
    }
  }, [fbLoginResponse]);

  useEffect(() => {
    try {
      if (pabblyLoginResponse.success) {
        const authCode1 = pabblyLoginResponse.data.data.authorizationCode;
        authCode2 = authCode1;
        setAuthCode(authCode1);
        if (authCode2) setShowLoginPage(false);
      }
    } catch (error) {
      console.error("Error while fetching authcode", error);
    }
  }, [pabblyLoginResponse]);

  const onSubmitForm = async (event) => {
    event.preventDefault();
    var credentials = btoa(email + ":" + password);
    var appId = process.env.REACT_APP_WEATHER_API_KEY;
    const clientId = allQueryParamsInURL.get("client_id");
    const redirectURI = allQueryParamsInURL.get("redirect_uri");
    const loginData = { username: email, password: password };
    var basicAuth = credentials;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Authorization"] = "Basic " + basicAuth;
    axios.defaults.headers.common["AppID"] = appId;
    axios.defaults.headers.common["clientid"] = clientId;
    if (email == "" || password == "") {
      openNameEmailValidation({ vertical: "top", horizontal: "center" });
    } else {
      ReactGA.event({ category: "sso", action: "signIn", label: "signIn" });
      const queryParam = { redirectURI: redirectURI };
      registerOnPabbly(loginData, queryParam);
    }
  };

  const clickLogo = () => {
    if (searchQuery !== null) {
      props.history.push("/?redirect=" + searchQuery);
    } else {
      props.history.push("/");
    }
    ReactGA.event({ category: "sso", action: "logo", label: "logo" });
  };

  const onClickAllowPermission = () => {
    setAllowPermissions(true);
    const clientId = allQueryParamsInURL.get("client_id");
    const redirectURI = allQueryParamsInURL.get("redirect_uri");
    const state = allQueryParamsInURL.get("state");
    handleClick7({ vertical: "top", horizontal: "center" });
    window.location.href =
      redirectURI + `?code=${authCode}` + `&state=${state}`;
  };

  const onClickDenyPermission = () => {
    setAllowPermissions(false);
    const clientId = allQueryParamsInURL.get("client_id");
    const redirectURI = allQueryParamsInURL.get("redirect_uri");
    const state = allQueryParamsInURL.get("state");
    handleClick7({ vertical: "top", horizontal: "center" });
    window.location.href = redirectURI + `?error=access_denied`;
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifycontent="center"
        alignItems="center"
        style={{
          backgroundColor: "white",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item md={6} lg={7} xs={12}>
          <Container
            className={classes.container_color}
            component="main"
            maxWidth="sm"
          >
            <CssBaseline />
            {showLoginPage && (
              <div>
                <div className={classes.avatar} display="flex">
                  <img
                    style={{ width: "130px" }}
                    display="inline"
                    src={FlutinLogoWithCamera}
                  />
                  <span style={{ paddingLeft: "3%", paddingRight: "3%" }}>
                    {" "}
                    with{" "}
                  </span>
                  <img
                    style={{ width: "130px" }}
                    display="inline"
                    src={PabblyLogo}
                  />
                </div>
                <div className={classes.sub_container}>
                  <Typography
                    className={classes.title}
                    component="h2"
                    variant="h5"
                  >
                    {" "}
                    <b>{Title}</b>
                  </Typography>
                  {/* <Typography className={classes.welcome_text} component="h1" variant="h6" > Welcome Sumo-ling! </Typography> */}
                  <Typography
                    className={classes.Sign_in}
                    component="h1"
                    variant="h6"
                  >
                    {" "}
                    Please Login to get started with Flutin.{" "}
                  </Typography>
                  <ValidatorForm className={classes.form}>
                    <div>
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        value={email}
                        type="email"
                        onChange={onChangeEmail}
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "this field is required",
                          "email is not valid",
                        ]}
                        autoFocus
                      />
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={password}
                        onChange={onChangePassword}
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={onSubmitForm}
                      >
                        {" "}
                        Sign In{" "}
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            )}

            {!showLoginPage && (
              <div className="permissionPopup">
                <div className="zapierTopLogoContainer">
                  <Typography component="h4" variant="h4">
                    Authorization Request
                  </Typography>
                </div>
                <div className="logoContainer">
                  <div className="zapierLogoContainer">
                    <img className="zapierLogo" src={PabblyLogo} />
                  </div>
                  <div className="middleLogoContainer">+</div>
                  <div className="flutinLogoContainer">
                    <img className="flutinLogo" src={FlutinLogoWithCamera} />
                  </div>
                </div>
                <div className="mainCard">
                  <div>
                    <Typography component="h5" variant="h5">
                      {" "}
                      Pabbly is requesting permission to access your Flutin
                      account.{" "}
                    </Typography>
                  </div>
                  <div>
                    <Typography component="h6" variant="h6">
                      {" "}
                      Pabbly will be able to{" "}
                    </Typography>
                    <ul>
                      <li> Manage your data. </li>
                      <li> Post on behalf of you. </li>
                    </ul>
                  </div>
                </div>
                <div className="buttonContainer">
                  <Button
                    className="secondaryButton"
                    variant="contained"
                    color="default"
                    onClick={() => onClickDenyPermission()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="primaryButton"
                    variant="contained"
                    color="primary"
                    onClick={() => onClickAllowPermission()}
                  >
                    Allow
                  </Button>
                </div>
                <div className="footer">
                  {" "}
                  <a href="">Privacy Policies</a>{" "}
                </div>
              </div>
            )}

            <Snackbar
              open={open}
              key="topcenter"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="success">
                {" "}
                Login success!{" "}
              </Alert>
            </Snackbar>
            <Snackbar
              open={open1}
              key="topcenter1"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {" "}
                {loginResponse.error.data}{" "}
              </Alert>
            </Snackbar>
            <Snackbar
              open={open2}
              key="topcenter2"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="info">
                {" "}
                Please fill All the fields{" "}
              </Alert>
            </Snackbar>
            <Snackbar
              open={open3}
              key="topcenter3"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {" "}
                {fbLoginResponse.error.data}{" "}
              </Alert>
            </Snackbar>
            <Snackbar
              open={open4}
              key="topcenter4"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="info">
                {" "}
                {Message}{" "}
              </Alert>
            </Snackbar>
            <Snackbar
              open={open5}
              key="topcenter5"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {" "}
                {appSumoRegisterResponse.error}{" "}
              </Alert>
            </Snackbar>
            <Snackbar
              open={open6}
              key="topcenter6"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {" "}
                {appSumoRegisterResponse.error}{" "}
              </Alert>
            </Snackbar>
            <Snackbar
              open={open7}
              key="topcenter7"
              autoHideDuration={4000}
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="info">
                {" "}
                Redirecting...{" "}
              </Alert>
            </Snackbar>
          </Container>
        </Grid>
        {showLoginPage && (
          <Hidden smDown>
            <Grid item md={6} lg={5} className={classes.mainImage}></Grid>
          </Hidden>
        )}
      </Grid>
    </div>
  );
}
