import * as passServices from '../../Services/ForgetPassEmailServices';

export const FORGETPASSWORDEMAIL_SUCCESS = '[FORGETPASSWORD PAGE] FORGETPASSWORDEMAIL SUCCESS';
export const FORGETPASSWORDEMAIL_ERROR = '[FORGETPASSWORD PAGE] FORGETPASSWORDEMAIL ERROR';


export function ForgetPassEmailAction (data) {

    return (dispatch) => 
    passServices.ForgetPasswordEmail(data)
        .then( response => {
            return dispatch({
                type: FORGETPASSWORDEMAIL_SUCCESS,
                payload:response,
                msg: response.data.message

            })
        })
        .catch( err => {
           return dispatch({
                type: FORGETPASSWORDEMAIL_ERROR,
                payload: err.message
            })
        })
}

