import * as passServices from '../../Services/forgetPassServices';

export const FORGETPASSWORD_SUCCESS = '[FORGETPASSWORD PAGE] FORGETPASSWORD SUCCESS';
export const FORGETPASSWORD_ERROR = '[FORGETPASSWORD PAGE] FORGETPASSWORD ERROR';


export function ForgetPassAction (data) {

    return (dispatch) => 
    passServices.ForgetPassword (data)
        .then( response => {
            return dispatch({
                type: FORGETPASSWORD_SUCCESS,
                payload:response,
                msg: response.data.message

            })
        })
        .catch( err => {
           return dispatch({
                type: FORGETPASSWORD_ERROR,
                payload: err.message
            })
        })
}

