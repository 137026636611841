import * as fbLoginServices from "../../Services/fbLoginServices";

export const FB_SUCCESS = "[FB PAGE] FB SUCCESS";
export const FB_ERROR = "[FB PAGE] FB ERROR";

export function fbLoginAction(data) {
  // console.log('response1',email)

  return (dispatch) =>
    fbLoginServices
      .signInWithFacebook(data)
      .then((response) => {
        return dispatch({
          type: FB_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        return dispatch({
          type: FB_ERROR,
          payload: err,
          payload1: err.message,
        });
      });
}
