import axios from "axios";
var url = process.env.REACT_APP_WEATHER_API_URL;

export const getEmailVerifyLink = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}/v1/sso/sendVerificationEmail`, data)
      .then((response) => {
        if (response.data.success == true) {
          // if (response) {
          resolve(response.data);
          // response.headers.set["set-cookie"]
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitOTP = async (data) => {
  try {
    const response = await axios.post(`${url}/v1/sso/verifyOtp`, { otp: data });
    return response.data;
  } catch (err) {
    throw err;
  }
};
