import * as services from '../../Services/testingServices';

export const TESTING = '[LANDING PAGE] TESTING';
export const TESTING_SUCCESS = '[LANDING PAGE] TESTING SUCCESS';
export const TESTING_ERROR = '[LANDING PAGE] TESTING ERROR';


export function testingAction() {
    console.log('testing action')

    return (dispatch) => 
    services.testingService()
        .then( response => {
            return dispatch({
                type: TESTING_SUCCESS,
                payload: response
            })
        })
        .catch( err => {
           return dispatch({
                type: TESTING_ERROR,
                payload: err
            })
        })
}