import * as Actions from "../actions";

const initialState = {
  success: false,
  error: null,
  msg: null,
};

const appSumpRegister = function (state = initialState, action) {
  switch (action.type) {
    case Actions.APPSUMO_REGISTER_SUCCESS: {
      return {
        ...initialState,
        success: true,
        data: action.payload,
        msg: action.msg,
        // access_token:action.token
      };
    }
    case Actions.APPSUMO_REGISTER_ERROR: {
      return {
        success: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default appSumpRegister;
