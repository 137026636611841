import * as getEmailVerifyLinkService from "../../Services/getEmailVerifyLinkServices";
export const EMAIL_VERIFY_LINK_SUCCESS = "[EMAIL_VERIFY_LINK PAGE] EMAIL_VERIFY_LINK SUCCESS";
export const EMAIL_VERIFY_LINK_ERROR = "[EMAIL_VERIFY_LINK PAGE] EMAIL_VERIFY_LINK ERROR";
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_ERROR = "OTP_VERIFY_ERROR";
export function getEmailVerifyLinkAction(data) {
  return (dispatch) =>
    getEmailVerifyLinkService
      .getEmailVerifyLink(data)
      .then((response) => {
        return dispatch({
          type: EMAIL_VERIFY_LINK_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        return dispatch({
          type: EMAIL_VERIFY_LINK_ERROR,
          payload: err,
          // payload1: err.message,
        });
      });
}

export function submitOTP(data) {
  return async (dispatch) => {
    try {
      const response = await getEmailVerifyLinkService.submitOTP(data);
      dispatch({ type: response.success ? OTP_VERIFY_SUCCESS : OTP_VERIFY_ERROR, payload: response });
    } catch (err) {
      err = new Error(err);
      dispatch({ type: OTP_VERIFY_ERROR, payload: err });
    }
  };
}