import * as appSumpRegisterService from "../../Services/appSumoRegisterServices";

export const APPSUMO_REGISTER_SUCCESS = "[REGISTER PAGE] REGISTER SUCCESS";
export const APPSUMO_REGISTER_ERROR = "[REGISTER PAGE] REGISTER ERROR";

export function appSumoSignUpAction(data) {
  return (dispatch) =>
    appSumpRegisterService
      .signUpWithEmailAndPassword(data)
      .then((response) => {
        return dispatch({
          type: APPSUMO_REGISTER_SUCCESS,
          payload: response,
          msg: response.data.message,
          // token:response.data.data.token
        });
      })
      .catch((err) => {
        return dispatch({
          type: APPSUMO_REGISTER_ERROR,
          payload: err.message,
        });
      });
}
