import * as passServices from '../../Services/changePassServices';

export const CHANGEPASSWORD_SUCCESS = '[CHANGEPASSWORD PAGE] CHANGEPASSWORD SUCCESS';
export const CHANGEPASSWORD_ERROR = '[CHANGEPASSWORD PAGE] CHANGEPASSWORD ERROR';


export function ChangePassAction (data) {

    return (dispatch) => 
    passServices.ChangePassword (data)
        .then( response => {
            return dispatch({
                type: CHANGEPASSWORD_SUCCESS,
                payload:response.data,
                msg: response.data.message

            })
        })
        .catch( err => {
           return dispatch({
                type: CHANGEPASSWORD_ERROR,
                payload: err.message
            })
        })
}

