import * as Actions from "../actions";

const initialState = {
  success: false,
  error: {
    success: true,
  },
  data: null,
};

const forgotPasswordLink = function (state = initialState, action) {
  switch (action.type) {
    case Actions.FORGOT_PASSWORD_LINK_SUCCESS: {
      return {
        ...initialState,
        success: true,
        data: action.payload,
        // interim_token:action.token
      };
    }
    case Actions.FORGOT_PASSWORD_LINK_ERROR: {
      return {
        success: false,
        error: {
          success: true,
          data: action.payload1,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default forgotPasswordLink;
