import * as Actions from "../actions/verifyemail.action";

const initialState = {
  isLoading: false,
  data: null,
  success: false,
  error: {},
};

const verifyEmail = (state = initialState, action) => {
  switch (action.type) {
    case Actions.EMAIL_SUCCESS:
      return {
        ...initialState,
        success: true,
        isLoading: false,
        data: action.payload,
      };

    case Actions.EMAIL_ERROR:
      return {
        ...state,
        success: false,
        isLoading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
export default verifyEmail;
