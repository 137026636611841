import * as registerServices from "../../Services/registerServices";

export const REGISTER_SUCCESS = "[REGISTER PAGE] REGISTER SUCCESS";
export const REGISTER_ERROR = "[REGISTER PAGE] REGISTER ERROR";

export function registerAction(data) {
  // console.log('response1',email)

  return (dispatch) =>
    registerServices
      .signUpWithEmailAndPassword(data)
      .then((response) => {
        return dispatch({
          type: REGISTER_SUCCESS,
          payload: response,
          msg: response.data.message,
        });
      })
      .catch((err) => {
        return dispatch({
          type: REGISTER_ERROR,
          payload: err.message,
        });
      });
}
