import axios from "axios";
var url = process.env.REACT_APP_WEATHER_API_URL;
const zapierUrl = `${url}/v1/sso/zapier`;
export const signInZapier = (data, queryParams) => {
  return new Promise((resolve, reject) => {
    axios.get(zapierUrl + "/auth" + `?redirect_uri=${queryParams.redirectURI}`, data)
      .then((response) => {
        if (response.data.success == true) resolve(response.data);
        else reject(response.data.error);
      })
      .catch((err) => { err.message ? reject(err.message) : reject(err) });
  });
};
