import * as pabblyServices from "../../Services/pabblyService";
import * as registerService from "../../Services/registerServices";
export const LOGIN_SUCCESS = "[LOGIN PAGE] LOGIN SUCCESS";
export const SIGNUP_SUCCESS = "[SIGNUP PAGE] SIGNUP SUCCESS";
export const LOGIN_ERROR = "[LOGIN PAGE] LOGIN ERROR";

export function pabblyLoginAction(data, queryParams) {
  return (dispatch) =>
    pabblyServices
      .signInPabbly(data, queryParams)
      .then((response) => {
        return dispatch({ type: LOGIN_SUCCESS, payload: response });
      })
      .catch((err) => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: err,
          payload1: err.message,
        });
      });
}
