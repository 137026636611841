import * as logoutService from "../../Services/logoutServices";

export const LOGOUT_SUCCESS = "[LOGOUT PAGE] LOGOUT SUCCESS";
export const LOGOUT_ERROR = "[LOGOUT PAGE] LOGOUT ERROR";

export function logoutAction(data) {
  return (dispatch) =>
    logoutService
      .logoutService(data)
      .then((response) => {
        return dispatch({
          type: LOGOUT_SUCCESS,
          payload: response,
          // token:response.data.data.token
        });
      })
      .catch((err) => {
        return dispatch({
          type: LOGOUT_ERROR,
          payload: err,
        });
      });
}
