export const loadSendinblueTracker = (userEmail,event) => {
    let Trackerscript = document.createElement("script");
    Trackerscript.innerHTML = `(function() {
          window.sib = {
              equeue: [],
              client_key: "${process.env.REACT_APP_SIBCLIENT_KEY}",
          };
          /* OPTIONAL: email for identify request*/
          window.sib.email_id = "${userEmail}";
          window.sendinblue = {};
          for (var j = ['track', 'identify', 'trackLink', 'page'], i = 0; i < j.length; i++) {
          (function(k) {
              window.sendinblue[k] = function() {
                  var arg = Array.prototype.slice.call(arguments);
                  (window.sib[k] || function() {
                          var t = {};
                          t[k] = arg;
                          window.sib.equeue.push(t);
                      })(arg[0], arg[1], arg[2], arg[3]);
                  };
              })(j[i]);
          }
          var n = document.createElement("script"),
              i = document.getElementsByTagName("script")[0];
          n.type = "text/javascript", n.id = "sendinblue-js", n.async = !0, n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key, i.parentNode.insertBefore(n, i),window.sendinblue.track("${event}");
      })();`;
    Trackerscript.async = true;
    document.getElementsByTagName("head")[0].appendChild(Trackerscript);
};



