import * as emailServices from "../../Services/verifyemailServices";

export const EMAIL_SUCCESS = "[EMAIL PAGE] EMAIL SUCCESS";
export const EMAIL_ERROR = "[EMAIL PAGE] EMAIL ERROR";

export function getEmailVerified(data) {
  return (dispatch) =>
    emailServices
      .VerifyEmailService(data)
      .then((response) => {
        return dispatch({
          type: EMAIL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        return dispatch({
          type: EMAIL_ERROR,
          payload: err,
        });
      });
}
