import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import ReactGA from "react-ga4";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "../store/actions/index";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Flutin from "./Images/Flutin.svg";

// import withReducer from "app/store/withReducer";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper1: {
    marginTop: theme.spacing(30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(8),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    background: "#122BA9",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    color: "white",
    height: "60px",
    fontWeight: 800,
  },
  container_color: {
    backgroundColor: "white",
    paddingLeft: "32px",
    paddingRight: "32px",
  },
}));

export default function ChangePassword(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ChangePwdResponse = useSelector((state) => state.ChangePass);

  const [oldPassword, setoldPassword] = React.useState("");
  const [newPassword, setnewPassword] = React.useState("");
  const [confirmPassword, setconfirmPassword] = React.useState("");

  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

  const searchQuery = new URLSearchParams(window.location.search).get(
    "redirect"
  );

  const [OpenState, setOpen] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState1, setOpen1] = React.useState({
    open1: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState2, setOpen2] = React.useState({
    open2: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState3, setOpen3] = React.useState({
    open3: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = OpenState;
  const { open1 } = OpenState1;
  const { open2 } = OpenState2;
  const { open3 } = OpenState3;

  var value = process.env.REACT_APP_WEATHER_API_KEY;
  axios.defaults.headers.common["AppID"] = value;
  axios.defaults.withCredentials = true;

  // localStorage.setItem('jwt_token', token);

  // console.log('token',token)

  const handleClick = (newState) => {
    setOpen({ open: true, ...newState });
  };
  const handleClick1 = (newState1) => {
    setOpen1({ open1: true, ...newState1 });
  };
  const handleClick2 = (newState2) => {
    setOpen2({ open2: true, ...newState2 });
  };
  const handleClick3 = (newState3) => {
    setOpen3({ open3: true, ...newState3 });
  };

  const handleClose = () => {
    setOpen({ ...OpenState, open: false });
    setOpen1({ ...OpenState1, open1: false });
    setOpen2({ ...OpenState2, open2: false });
    setOpen3({ ...OpenState3, open3: false });
  };

  const onChangeNewPassword = (event) => {
    setnewPassword(event.target.value);
  };

  const onChangeConfirmPassword = (event) => {
    setconfirmPassword(event.target.value);
  };

  const onChangeOldPassword = (event) => {
    setoldPassword(event.target.value);
  };

  const onClickForget = useCallback((data) => {
    dispatch(Actions.ChangePassAction(data));
  });

  const clickLogo = () => {
    if (searchQuery !== null && searchQuery !== "null") {
      props.history.push("/?redirect=" + searchQuery);
    } else {
      props.history.push("/");
    }
    ReactGA.event({
      category: "sso",
      action: "logo",
      label: "logo",
    });
  };

  // const name = window.$name;
  // console.log(name); //
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    if (ChangePwdResponse.success != false) {
      handleClick({ vertical: "top", horizontal: "center" });
    }
    if (ChangePwdResponse.success == false && ChangePwdResponse.error != null) {
      handleClick3({ vertical: "top", horizontal: "center" });
    }
  }, [ChangePwdResponse]);

  const handleForgetPass = async (event) => {
    try {
      event.preventDefault();
      if (newPassword === confirmPassword && newPassword != "" && newPassword.length >= 5) {
        const data = { currentPassword: oldPassword, newPassword: newPassword, confirmPassword: confirmPassword, };
        ReactGA.event({ category: "sso", action: "changePassword", label: "changePassword", });
        await onClickForget(data);
        window.location = searchQuery;
      } else if (newPassword == "" || confirmPassword == "" || newPassword.length < 5 || oldPassword == "") {
        handleClick1({ vertical: "top", horizontal: "center" });
      } else {
        handleClick2({ vertical: "top", horizontal: "center" });
      }
    } catch (error) {
      console.error(error)
    }
  };

  return (
    <Container
      className={classes.container_color}
      component="main"
      maxWidth="xs"
    >
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.avatar}>
          <img style={{ cursor: "pointer" }} onClick={clickLogo} src={Flutin} />
        </div>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            id="password3"
            value={oldPassword}
            inputProps={{ maxLength: 200 }}
            onChange={onChangeOldPassword}
            label="Current Password"
            name="old password"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            id="password1"
            value={newPassword}
            inputProps={{ maxLength: 200 }}
            onChange={onChangeNewPassword}
            label="New Password"
            name="new password"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            inputProps={{ maxLength: 200 }}
            name="Confirm password"
            label="Confirm Password"
            type="password"
            id="password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleForgetPass}
          >
            Submit
          </Button>
        </form>
      </div>
      <Snackbar
        open={open}
        key="topcenter"
        autoHideDuration={4000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {ChangePwdResponse.msg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={open1}
        key="topcenter1"
        autoHideDuration={4000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="info">
          Please fill both the fields with minimum 5 character
        </Alert>
      </Snackbar>
      <Snackbar
        open={open2}
        key="topcenter2"
        autoHideDuration={4000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          Your password must be same
        </Alert>
      </Snackbar>
      <Snackbar
        open={open3}
        key="topcenter3"
        autoHideDuration={4000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {ChangePwdResponse.error}
        </Alert>
      </Snackbar>
    </Container>
  );
}
