import axios from "axios";
var url = process.env.REACT_APP_WEATHER_API_URL;

export const getForgotPasswordLink = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}/v1/sso/sendForgotPasswordLink`)
      .then((response) => {
        if (response.data.success == true) {
          resolve(response.data);
          console.log("forgot", response);
          // response.headers.set["set-cookie"]
        } else {
          reject(response.data.error);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
