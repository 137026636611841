import * as getForgotPasswordLinkServices from "../../Services/getForgotPasswordLinkServices";

export const FORGOT_PASSWORD_LINK_SUCCESS =
  "[FORGOT_PASSWORD_LINK PAGE] FORGOT_PASSWORD_LINK SUCCESS";
export const FORGOT_PASSWORD_LINK_ERROR =
  "[FORGOT_PASSWORD_LINK PAGE] FORGOT_PASSWORD_LINK ERROR";

export function getForgotPasswordAction() {
  return (dispatch) =>
    getForgotPasswordLinkServices
      .getForgotPasswordLink()
      .then((response) => {
        return dispatch({
          type: FORGOT_PASSWORD_LINK_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        return dispatch({
          type: FORGOT_PASSWORD_LINK_ERROR,
          payload: err,
          payload1: err.message,
        });
      });
}
