import * as iftttServices from "../../Services/iftttService";
import * as registerService from "../../Services/registerServices";
export const LOGIN_SUCCESS = "[LOGIN PAGE] LOGIN SUCCESS";
export const SIGNUP_SUCCESS = "[SIGNUP PAGE] SIGNUP SUCCESS";
export const LOGIN_ERROR = "[LOGIN PAGE] LOGIN ERROR";

export function iftttLoginAction(data, queryParams) {
  return (dispatch) =>
    iftttServices
      .signInIfttt(data, queryParams)
      .then((response) => {
        return dispatch({ type: LOGIN_SUCCESS, payload: response });
      })
      .catch((err) => {
        console.log("iftttLoginAction err---->",err);
        return dispatch({
          type: LOGIN_ERROR,
          payload: err,
          payload1: err.message,
        });
      });
}
