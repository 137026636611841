import React, { useEffect, useCallback } from "react";
import axios from "axios";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
// import * as Actions from "../store/actions/register.action";
import * as Actions from "../store/actions/index";

import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import CssBaseline from "@material-ui/core/CssBaseline";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Flutin from "./Images/Flutin.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import Hidden from "@material-ui/core/Hidden";

import MainImage from "./Images/right-img.png";
import MainImageMobile from "./Images/Mobile-img.png"
import lock from "./Images/lock.svg";
import Line from "./Images/Line.svg";
import * as sibTrackerService from "../Services/sibTrackerService";
const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      backgroundColor: '#965aff',
      color: '#FFFFFF',
    }
  },
  paper: {
    // marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    // marginTop: theme.spacing(8),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    background: "#6F39CE",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    color: "white",
    height: "60px",
    fontWeight: 800,
  },
  title: {
    color: "#767676",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
  },
  Sign_up: {
    marginTop: theme.spacing(3),
    color: "#9D9D9D",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
  },
  text_style: {
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: 500,
  },
  align_signin: {
    textAlign: "center",
    cursor: "pointer",
    fontSize: "14px",
    display: "block",
    color: "#9A9A9A",
    marginBottom: "24px",
  },
  align_Back: {
    textAlign: "center",
    cursor: "pointer",
    fontSize: "14px",
    display: "block",
    color: "#9A9A9A",
    marginBottom: "24px",
    display: "flex",
    justifyContent: "center",
  },
  display: {
    display: "block",
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  color: {
    color: "#6F39CE",
  },
  container_color: {
    backgroundColor: "white",
    paddingLeft: "32px",
    paddingRight: "32px",
  },
  gradient_background: {

    "@media (max-width: 767px)": {
      backgroundImage: "url(" + MainImageMobile + ")",
      backgroundSize: "cover",
      position: "absolute",
      width: "100%",
      top: "-14px",
      height: "320px",
    },
  },
  mainHeader: {
    marginTop: "77px",
    "@media (max-width: 767px)": {
      marginTop: "196px",
    },
  },
  mainHeader1: {
    fontFamily: "Exo",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "60px",
    textAlign: "center",
    color: "#FFFFFF",
    "@media (max-width: 767px)": {
      fontSize: "18px",
      lineHeight: "28px",
    },
  },
  mainHeader2: {
    fontFamily: "Exo",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#FFFFFF",
    "@media (max-width: 767px)": {
      fontSize: "18px",
      lineHeight: "28px",
    },
  },
  mainImage: {
    backgroundImage: "url(" + MainImage + ")",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  mainGrid: {
    backgroundColor: "white",
    "@media (min-width: 767px)": {
      height: "100vh"
    },
  },
  grid: {
    "@media (max-width: 767px)": {
      zIndex: 1,
      position: "relative",
      top: "235px",
      padding: "16px"
    },
  }
}));

export default function SignUp(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const registerResponse = useSelector((state) => state.register);
  const loginResponse = useSelector((state) => state.login);

  // const registerResponse1 = useSelector((state) => state.register);
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: true, // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, options);

  var searchQuery = new URLSearchParams(window.location.search).get("redirect");
  const emailFromURL = new URLSearchParams(window.location.search).get('email');
  if (searchQuery !== null && searchQuery !== "null") {
    searchQuery = searchQuery.replace("?", "&");
  }
  const [name, setname] = React.useState("");
  const [email, setemail] = React.useState(emailFromURL ? emailFromURL : "");
  const [disableEmail] = React.useState(emailFromURL ? true : false);
  const [password, setpassword] = React.useState("");
  const [Title, setTitle] = React.useState("");

  const [checkState, setcheckState] = React.useState(false);
  const [Message, setMessage] = React.useState("");

  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID, { debug: true });

  const [OpenState, setOpen] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState1, setOpen1] = React.useState({
    open1: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState2, setOpen2] = React.useState({
    open2: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState3, setOpen3] = React.useState({
    open3: false,
    vertical: "top",
    horizontal: "center",
  });

  const [OpenState4, setOpen4] = React.useState({
    open4: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = OpenState;
  const { open1 } = OpenState1;
  const { open2 } = OpenState2;
  const { open3 } = OpenState3;
  const { open4 } = OpenState4;

  // const name = window.$name;
  // console.log(name); //

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleChange = (event) => {
    setcheckState(event.target.checked);
  };

  const onChangeName = (event) => {
    setname(event.target.value);
  };

  const onChangeEmail = (event) => {
    setemail(event.target.value);
  };

  const onChangePassword = (event) => {
    setpassword(event.target.value);
  };

  const handleClick = (newState) => {
    setOpen({ open: true, ...newState });
  };
  const handleClick1 = (newState1) => {
    setOpen1({ open1: true, ...newState1 });
  };
  const handleClick2 = (newState2) => {
    setOpen2({ open2: true, ...newState2 });
  };
  const handleClick3 = (newState3) => {
    setOpen3({ open3: true, ...newState3 });
  };
  const handleLogin = (newState4) => {
    setOpen4({ open4: true, ...newState4 });
  };

  const handleClose = () => {
    setOpen({ ...OpenState, open: false });
    setOpen1({ ...OpenState1, open1: false });
    setOpen2({ ...OpenState2, open2: false });
    setOpen3({ ...OpenState3, open3: false });
    setOpen4({ ...OpenState4, open4: false });
  };

  const onClickRegister = useCallback((data) => {
    dispatch(Actions.registerAction(data));
  });

  const onClickLogin = useCallback((data) => {
    dispatch(Actions.loginAction(data));
  });

  useEffect(() => {
    onClickLogin();
  }, []);

  useEffect(() => {
    if (searchQuery !== null && searchQuery !== "null") {
      var parser = new URL(`${searchQuery}`);

      if (
        parser.host == "dev.creator.flutin.com" ||
        parser.host == "creator.flutin.com" ||
        parser.host == "localhost:4200"
      ) {
        setTitle(process.env.REACT_APP_CREATOR);
      } else if (
        parser.host == "dev.live.flutin.com" ||
        parser.host == "live.flutin.com" ||
        parser.host == "localhost:4201"
      ) {
        setTitle(process.env.REACT_APP_LIVE);
      } else {
        setTitle("");
      }
    }
  }, []);

  useEffect(() => {
    if (registerResponse.success != false) {
      if (registerResponse.data.success == true) {
        setMessage(registerResponse.data.message);
        ReactGA.set({userId:registerResponse.data.userId});
        ReactPixel.track("CompleteRegistration", { content_category: "Email" });
        sibTrackerService.loadSendinblueTracker(email, 'SignUp');
        if (searchQuery !== null && searchQuery !== "null") {
          var parser = new URL(`${searchQuery}`);

          if (
            parser.host == "dev.live.flutin.com" ||
            parser.host == "live.flutin.com" ||
            parser.host == "localhost:4201"
          ) {
            ReactGA.event({
              category: "sso",
              action: "SignUp successfully",
              label: "SignUp-Live-Email",
            });

            var parser = new URL(`${searchQuery}`);
            window.location = `${parser.origin}/tokenAuth/verify?redirect=${searchQuery}&token=${registerResponse.data.data.token}`;
          } else {
            ReactGA.event({
              category: "sso",
              action: "SignUp successfully",
              label: "SignUp-Creator-Email",
            });
            handleClick({ vertical: "top", horizontal: "center" });
            getLinkAgain();
          }
        } else {
          ReactGA.event({
            category: "sso",
            action: "SignUp successfully",
            label: "SignUp-Accounts-Email",
          });
          handleClick({ vertical: "top", horizontal: "center" });
          getLinkAgain();
        }
      }
      registerResponse.success = false;
    }
    if (loginResponse.success != false) {
      if (
        loginResponse.data.success == true &&
        loginResponse.data.verified == true
      ) {
        // handleLogin({ vertical: "top", horizontal: "center" });

        if (searchQuery !== null && searchQuery !== "null") {
          var parser = new URL(`${searchQuery}`);

          window.location = `${parser.origin}/tokenAuth/verify?redirect=${searchQuery}&token=${loginResponse.data.data.token}`;
        }
      }
      loginResponse.success = false;
    }
    if (registerResponse.success == false && registerResponse.error != null) {
      handleClick2({ vertical: "top", horizontal: "center" });
    }
  }, [registerResponse, loginResponse]);

  const getLinkAgain = () => {
    if (searchQuery !== "null" && searchQuery !== null) {
      props.history.push("/getLink?redirect=" + searchQuery);
    } else {
      props.history.push("/getLink");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // if()
    const data = {
      redirect: searchQuery,
    };
    // var value = 'b36bc78d-09a4-4182-b90f-2f40a087606b'
    var value = process.env.REACT_APP_WEATHER_API_KEY;
    // var credentials = btoa(name + ":" + email + ":" + password);
    var credentials = btoa(email + ":" + password);
    var basicAuth = "Basic " + credentials;

    axios.defaults.headers.common["Authorization"] = basicAuth;
    axios.defaults.headers.common["AppID"] = value;
    axios.defaults.withCredentials = true;

    if (email == "" || password == "") {
      // if (email == "" || password == "" || name == "") {
      handleClick1({ vertical: "top", horizontal: "center" });
    } else if (password.length < 6) {
      handleClick3({ vertical: "top", horizontal: "center" });
    } else {
      ReactPixel.track("signUp", { content_category: "sso" });
      ReactGA.event({
        category: "sso",
        action: "signUp",
        label: "signUp",
      });
      await onClickRegister(data);
    }
  };

  const handleSignIn = () => {
    ReactGA.event({
      category: "sso",
      action: "backToLogin",
      label: "backToLogin",
    });
    if (searchQuery !== null && searchQuery !== "null") {
      props.history.push("/?redirect=" + searchQuery);
    } else {
      props.history.push("/");
    }
  };

  const clickLogo = () => {
    if (searchQuery !== null && searchQuery !== "null") {
      props.history.push("/?redirect=" + searchQuery);
    } else {
      props.history.push("/");
    }
    ReactGA.event({
      category: "sso",
      action: "logo",
      label: "logo",
    });
  };

  const Terms = () => {
    ReactGA.event({
      category: "sso",
      action: "termsAndCondition",
      label: "termsAndCondition",
    });
  };

  return (
    <div>
      <div className={classes.gradient_background}></div>
      <Grid
        container
        direction="row"
        justifycontent="center"
        alignItems="center"
        className={classes.mainGrid}
        style={{}}
      >
        <Grid item md={6} lg={7} xs={12} className={classes.grid}>
          <Container
            className={classes.container_color}
            component="main"
            maxWidth="sm"
          >
            <CssBaseline />
            <Container
              className={classes.container_color}
              component="main"
              maxWidth="xs"
            >
              <CssBaseline />
              <div className={classes.paper}>
                <div className={classes.avatar}>
                  <img
                    style={{ cursor: "pointer", width: "130px" }}
                    onClick={clickLogo}
                    src={Flutin}
                  />
                </div>
                <Typography className={classes.title} component="h1" variant="h5">
                  <b>{Title}</b>
                </Typography>
                <Typography className={classes.Sign_up} component="h1" variant="h6">
                  Sign Up with Email
                </Typography>
                <ValidatorForm className={classes.form}>
                  {/* <TextValidator
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            value={name}
            type="text"
            onChange={onChangeName}
            label="Name"
            name="name"
            validators={["required"]}
            errorMessages={["this field is required"]}
            autoFocus
          /> */}
                  <TextValidator
                    disabled={disableEmail}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    value={email}
                    type="email"
                    onChange={onChangeEmail}
                    label="Email Address"
                    name="email"
                    validators={["required", "isEmail"]}
                    errorMessages={["this field is required", "email is not valid"]}
                  />
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={password}
                    onChange={onChangePassword}
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    inputProps={{ maxLength: 200 }}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                  <Checkbox
                    checked={checkState}
                    onChange={handleChange}
                    name="checkedA"
                    color="primary"
                  />
                  I accept
                  <a
                    href="https://creator.flutin.com/privacy"
                    className={classes.text_style}
                    style={{ textDecoration: "none", color: "#6F39CE" }}
                    target="_blank"
                    onClick={Terms}
                  >
                    {" "}
                    <b>Terms and Conditions</b>
                  </a>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleSubmit}
                    disabled={!checkState}
                  >
                    Sign Up
                  </Button>
                  <Grid container className={classes.display}>
                    <Grid item>
                      <Typography
                        onClick={handleSignIn}
                        className={classes.align_signin}
                      >
                        Already have an account?{" "}
                        <b className={classes.color}>Sign In</b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.display}>
                    <Grid item>
                      <Typography onClick={handleSignIn} className={classes.align_Back}>
                        <div>
                          <svg
                            style={{ marginTop: "1px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="18"
                            fill="#6F39CE"
                            class="bi bi-chevron-left"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                            />
                          </svg>
                        </div>
                        <b className={classes.color}>Back</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </div>
              <Snackbar
                open={open}
                key="topcenter"
                autoHideDuration={4000}
                anchorOrigin={{ vertical, horizontal }}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  {Message}
                </Alert>
              </Snackbar>
              <Snackbar
                open={open1}
                key="topcenter1"
                autoHideDuration={4000}
                anchorOrigin={{ vertical, horizontal }}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  Please fill all the fields
                </Alert>
              </Snackbar>
              <Snackbar
                open={open2}
                key="topcenter2"
                autoHideDuration={4000}
                anchorOrigin={{ vertical, horizontal }}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {registerResponse.error}
                </Alert>
              </Snackbar>
              <Snackbar
                open={open3}
                key="topcenter3"
                autoHideDuration={4000}
                anchorOrigin={{ vertical, horizontal }}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  Password must be more than 6 characters
                </Alert>
              </Snackbar>
              <Snackbar
                open={open4}
                key="topcenter4"
                autoHideDuration={4000}
                anchorOrigin={{ vertical, horizontal }}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  Login success!
                </Alert>
              </Snackbar>
            </Container>
          </Container>
        </Grid>

        <Hidden smDown>
          <Grid item md={6} lg={5} className={classes.mainImage}></Grid>
        </Hidden>
      </Grid>
    </div>
  );
}
