import * as getEmailService from "../../Services/getEmailServices";

export const GET_EMAIL_SUCCESS = "[GET_EMAIL PAGE] GET_EMAIL SUCCESS";
export const GET_EMAIL_ERROR = "[GET_EMAIL PAGE] GET_EMAIL ERROR";

export function getEmailAction(data) {
  return (dispatch) =>
    getEmailService.getEmail(data).then((response) => { return dispatch({ type: GET_EMAIL_SUCCESS, payload: response, }); })
      .catch((err) => { return dispatch({ type: GET_EMAIL_ERROR, payload: err, }); });
}
