import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import ReactGA from "react-ga4";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "../store/actions/index";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Flutin from "./Images/Flutin.svg";
import Hidden from "@material-ui/core/Hidden";

import MainImage from "./Images/right-img.png";
import MainImageMobile from "./Images/Mobile-img.png"
import lock from "./Images/lock.svg";
import Line from "./Images/Line.svg";

// import withReducer from "app/store/withReducer";

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper1: {
    // marginTop: theme.spacing(30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    // marginTop: theme.spacing(8),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(12, 0, 2),
    background: "#6F39CE",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    color: "white",
    height: "60px",
    fontWeight: 800,
  },
  text_class: {
    cursor: "pointer",
    fontSize: "14px",
    color: "#6F39CE",
    textAlign: "right",
    marginBottom: "24px",
  },
  display: {
    display: "block",
  },
  Sign_in: {
    marginTop: theme.spacing(3),
    color: "#9D9D9D",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Roboto",
    textAlign: "center",
  },
  container_color: {
    backgroundColor: "white",
    paddingLeft: "32px",
    paddingRight: "32px",
  },
  title: {
    color: "#767676",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
  },
  gradient_background: {
    "@media (max-width: 767px)": { 
      backgroundImage: "url(" + MainImageMobile + ")",
      backgroundSize: "cover",
      position: "absolute",
      width: "100%",
      top: "-14px",
      height: "320px",
    },
  },
  mainHeader: {
    marginTop: "77px",
    "@media (max-width: 767px)": {
      marginTop: "196px",
    },
  },
  mainHeader1: {
    fontFamily: "Exo",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "60px",
    textAlign: "center",
    color: "#FFFFFF",
    "@media (max-width: 767px)": {
      fontSize: "18px",
      lineHeight: "28px",
    },
  },
  mainHeader2: {
    fontFamily: "Exo",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#FFFFFF",
    "@media (max-width: 767px)": {
      fontSize: "18px",
      lineHeight: "28px",
    },
  },
  mainImage: {
    backgroundImage: "url(" + MainImage + ")",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  mainGrid:{
    backgroundColor: "white",
    "@media (min-width: 767px)": {
      height: "100vh" 
    },
  },
  grid:{
    "@media (max-width: 767px)": {
      zIndex: 1,
      position: "relative",
      top: "235px",    
      padding: "16px"
    },
  }
}));

export default function ForgetPasswordEmail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const [Title, setTitle] = React.useState("");

  const ForgetPassResponse = useSelector((state) => state.ForgetPassEmail);
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
  // console.log('ForgetPassResponse',ForgetPassResponse)
  const searchQuery = new URLSearchParams(window.location.search).get(
    "redirect"
  );

  const [OpenState, setOpen] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState1, setOpen1] = React.useState({
    open1: false,
    vertical: "top",
    horizontal: "center",
  });
  const [OpenState2, setOpen2] = React.useState({
    open2: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = OpenState;
  const { open1 } = OpenState1;
  const { open2 } = OpenState2;

  var value = process.env.REACT_APP_WEATHER_API_KEY;
  axios.defaults.headers.common["AppID"] = value;

  // localStorage.setItem('jwt_token', token);

  // console.log('token',token)

  const handleClick = (newState) => {
    setOpen({ open: true, ...newState });
  };
  const handleClick1 = (newState1) => {
    setOpen1({ open1: true, ...newState1 });
  };
  const handleClick2 = (newState2) => {
    setOpen2({ open2: true, ...newState2 });
  };

  const handleClose = () => {
    setOpen({ ...OpenState, open: false });
    setOpen1({ ...OpenState1, open1: false });
    setOpen2({ ...OpenState2, open2: false });
  };

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const onClickForget = useCallback((data) => {
    dispatch(Actions.ForgetPassEmailAction(data));
  });

  // const name = window.$name;
  // console.log(name); //
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    if (ForgetPassResponse.success != false) {
      if (ForgetPassResponse.data.success == true) {
        handleClick({ vertical: "top", horizontal: "center" });
      }
    }
    if (
      ForgetPassResponse.success == false &&
      ForgetPassResponse.error != null
    ) {
      handleClick2({ vertical: "top", horizontal: "center" });
    }
  }, [ForgetPassResponse]);

  useEffect(() => {
    if (searchQuery !== null && searchQuery !== "null") {
      var parser = new URL(`${searchQuery}`);
      if (
        parser.host == "dev.creator.flutin.com" ||
        parser.host == "creator.flutin.com" ||
        parser.host == "localhost:4200"
      ) {
        setTitle(process.env.REACT_APP_CREATOR);
      } else if (
        parser.host == "dev.live.flutin.com" ||
        parser.host == "live.flutin.com" ||
        parser.host == "localhost:4201"
      ) {
        setTitle(process.env.REACT_APP_LIVE);
      } else {
        setTitle("");
      }
    }
  }, []);

  const handleEmail = async (event) => {
    event.preventDefault();
    if (email != "") {
      const data = {
        email: email,
        redirect: searchQuery,
      };
      ReactGA.event({
        category: "sso",
        action: "sendLinkToEmail",
        label: "sendLinkToEmail",
      });
      await onClickForget(data);
      // await handleClick({ vertical: 'top', horizontal: 'center' });
    } else {
      handleClick1({ vertical: "top", horizontal: "center" });
    }
  };

  const handleSignIn = () => {
    ReactGA.event({
      category: "sso",
      action: "backToLogin",
      label: "backToLogin",
    });
    if (searchQuery !== null && searchQuery !== "null") {
      props.history.push("/?redirect=" + searchQuery);
    } else {
      props.history.push("/");
    }
  };

  const clickLogo = () => {
    if (searchQuery !== null && searchQuery !== "null") {
      props.history.push("/?redirect=" + searchQuery);
    } else {
      props.history.push("/");
    }
    ReactGA.event({
      category: "sso",
      action: "logo",
      label: "logo",
    });
  };

  return (
    <div>
      <div className={classes.gradient_background}></div>
      <Grid
        container
        direction="row"
        justifycontent="center"
        alignItems="center"
        className={classes.mainGrid}
        style={{}}
      >
        <Grid item md={6} lg={7} xs={12} className={classes.grid}>
    <Container
      className={classes.container_color}
      component="main"
      maxWidth="xs"
    >
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.avatar}>
          <img
            style={{ cursor: "pointer", width: "130px" }}
            onClick={clickLogo}
            src={Flutin}
          />
        </div>
        <Typography className={classes.title} component="h2" variant="h5">
          <b>Reset Password</b>
        </Typography>
        <Typography className={classes.Sign_in} component="h1" variant="h5">
          Enter the email associated with your account and we’ll send an email
          with instruction to reset password
        </Typography>
        <ValidatorForm className={classes.form}>
          <TextValidator
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="email"
            id="email"
            value={email}
            onChange={onChangeEmail}
            label="Email"
            name="Email"
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleEmail}
          >
            SEND LINK TO EMAIL
          </Button>
          <Grid container className={classes.display}>
            <Grid item>
              <Typography onClick={handleSignIn} className={classes.text_class}>
                Back to Login
              </Typography>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
      <Snackbar
        open={open}
        key="topcenter"
        autoHideDuration={4000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {ForgetPassResponse.msg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={open1}
        key="topcenter1"
        autoHideDuration={4000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="info">
          Please fill the field
        </Alert>
      </Snackbar>
      <Snackbar
        open={open2}
        key="topcenter2"
        autoHideDuration={4000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {/* Error */}
          {ForgetPassResponse.error}
        </Alert>
      </Snackbar>
    </Container>
    </Grid>
<Hidden smDown>
  <Grid item md={6} lg={5} className={classes.mainImage}></Grid>
</Hidden>
</Grid>
</div>
  );
}
