import * as Actions from '../actions';

const initialState = { success: false, error: null, msg: null };
const zapierLoginReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.LOGIN_SUCCESS: { return { ...initialState, success: true, data: action.payload, msg: action.msg }; }
        case Actions.LOGIN_ERROR: { return { success: false, error: action.payload }; }
        default: { return state }
    }
};

export default zapierLoginReducer;