import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import ReactGA from "react-ga4";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "../store/actions/verifyemail.action";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Flutin from "./Images/Flutin.svg";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
// import withReducer from "app/store/withReducer";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper1: {
    marginTop: theme.spacing(30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(8),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(12, 0, 2),
    background: "#122BA9",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    color: "white",
    height: "60px",
    fontWeight: 800,
  },
  container_color: {
    backgroundColor: "white",
    paddingLeft: "32px",
    paddingRight: "32px",
  },
  title: {
    color: "#767676",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "26px",
    marginTop: "12px",
  },
}));

export default function EmailVerification(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const VerifyEmail = useSelector((state) => state.verifyEmail);
  var isLoading;
  if (VerifyEmail.data) {
    isLoading = VerifyEmail.data.success;
  }

  const searchQuery = new URLSearchParams(window.location.search).get("user");
  const searchQueryRedirect = new URLSearchParams(window.location.search).get(
    "redirect"
  );

  const token = searchQuery;
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

  // localStorage.setItem('jwt_token', token);
  var value = process.env.REACT_APP_WEATHER_API_KEY;
  axios.defaults.headers.common["AppID"] = value;

  useEffect(() => {
    if (token != null) {
      getEmailVerify(token);
    }
  }, []);

  useEffect(() => {
    if (VerifyEmail.data) {
      if (VerifyEmail.data.success) {
        handleSignIn();
      }
    }
  }, [VerifyEmail]);

  const handleSignIn = () => {
    ReactGA.event({
      category: "sso",
      action: "backToLogin",
      label: "backToLogin",
    });
    if (searchQueryRedirect !== null && searchQueryRedirect !== "null") {
      props.history.push("/?redirect=" + searchQueryRedirect);
    } else {
      props.history.push("/");
    }
  };

  const clickLogo = () => {
    ReactGA.event({
      category: "sso",
      action: "logo",
      label: "logo",
    });
    if (searchQueryRedirect !== null) {
      props.history.push("/?redirect=" + searchQueryRedirect);
    } else {
      props.history.push("/");
    }
  };

  const getEmailVerify = useCallback(async (data) => {
    await dispatch(Actions.getEmailVerified(data));
  });

  return (
    <React.Fragment>
      {!isLoading ? (
        <div className={classes.paper1}>
          <CircularProgress disableShrink />
          Loading...
        </div>
      ) : (
        <Container
          className={classes.container_color}
          component="main"
          maxWidth="xs"
        >
          <CssBaseline />
          <div className={classes.paper}>
            <div className={classes.avatar}>
              <img
                style={{ cursor: "pointer", width: "130px" }}
                onClick={clickLogo}
                src={Flutin}
              />
            </div>
            <Typography className={classes.title} component="h1" variant="h3">
              Your email has been verified
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSignIn}
            >
              Back to login page
            </Button>
          </div>
        </Container>
      )}
    </React.Fragment>
  );
}
